import { Formula } from '../../../../model/formula';
import { Pasos } from '../../../../model/pasos';

export interface IProps {
  formula: Formula;
}

export const OrdenFabricacionFormulaComponent = (props: IProps) => {
  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Materia Prima</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {props.formula.lista_elementos.map((element: Pasos) => (
            <tr>
              <td>
                {element.pausa
                  ? 'Dispersión'
                  : element.materiaprima
                  ? element.materiaprima.codigo.toUpperCase() +
                    ' | ' +
                    element.materiaprima.nombre
                  : 'Error al recuperar la materia prima'}
              </td>
              <td>
                {element.pausa
                  ? element.minutospausa! + 'm'
                  : element.valor! +
                    (element.materiaprima!.unidadDeMedida?.codigo ||
                      ' Codigo no encontrado')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
