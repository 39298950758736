import { MateriaPrima } from '../../../model/materia_prima';
import { Orden } from '../../../model/orden';

export interface IProps {
  orden: Orden;
}

export const OrdenFabricacionHeader = (props: IProps) => {
  return (
    <div className="EntityHeader">
      <small>Lote</small>
      <h1>{props.orden.lote}</h1>
      <small>Producto</small>
      <h2>{props.orden.producto?.codigo}</h2>
      <p>{props.orden.producto?.nombre}</p>
    </div>
  );
};

export default OrdenFabricacionHeader;
