import { useEffect, useState } from 'react';
import { MateriaPrimaForm } from '../components/materia_prima/materiaprimaForm';
import { MateriaPrimaTable } from '../components/materia_prima/materiaprimaTable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../stores';
import * as MateriaPrimaStore from '../stores/Materia_Prima';
import { MateriaPrima } from '../model/materia_prima';

export const MateriasPrimasPage = () => {
  const [ShowForm, setShowForm] = useState<boolean>(false);
  const [EditMateriaPrima, setEditMateriaPrima] = useState<MateriaPrima>();
  const dispatch = useDispatch();

  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  }, []);

  const MateriaPrimaSaveHandler = (savedMateriaPrima: MateriaPrima) => {
    console.log('grabo materia prima');
    setShowForm(false);
  };

  const MateriaPrimaEditHandler = (supply: MateriaPrima) => {
    setEditMateriaPrima(supply);
    setShowForm(true);
  };

  return (
    <>
      <div className="pageHeader">
        <h2>Materias Primas</h2>
        <button
          className="btn btn-primary"
          onClick={() => {
            setEditMateriaPrima(undefined);
            setShowForm(true);
          }}
        >
          Nueva materia prima
        </button>
      </div>
      {SuppliesState?.isLoadingAll ? (
        <div className="loadingFlag">Cargando...</div>
      ) : (
        <MateriaPrimaTable EditSupplyEvent={MateriaPrimaEditHandler} />
      )}
      <>
        {ShowForm && (
          <div className="modalForm">
            <h5>Información de la materia prima</h5>
            <MateriaPrimaForm
              MateriaPrimaSaveEvent={MateriaPrimaSaveHandler}
              CancelEvent={() => setShowForm(false)}
              supply={EditMateriaPrima}
            />
          </div>
        )}
      </>
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  ...state.Supplies
});

const mapDispatchToProps = {
  ...MateriaPrimaStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MateriasPrimasPage as any);
