// actions 
import { Action, Reducer } from 'redux';
import * as Actions from '../Actions/OrderActions';
import { OrderState, orderUnloadedState } from '../States/OrderState';


type KnownAction = 
    | Actions.Order_Cancel_Request_Action
    | Actions.Order_Cancel_Success_Action
    | Actions.Order_Cancel_Fail_Action;

export const OrderReducer: Reducer<OrderState> = (state: OrderState | undefined, incomingAction: Action): OrderState  => {

    if (state === undefined) {
        return orderUnloadedState as OrderState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'ORDER_CANCEL_REQUEST':
            return {
                ...state,
                candelingOrder: true,
                canceledOrderSuccessfuly: undefined,
                failOnCancelingOrder: false,
            };
        case 'ORDER_CANCEL_SUCCESS':
            return {
                ...state,
                candelingOrder: false,
                canceledOrderSuccessfuly: true,
                failOnCancelingOrder: false,
            };
        case 'ORDER_CANCEL_FAIL':
            return {
                ...state,
                candelingOrder: false,
                canceledOrderSuccessfuly: false,
                failOnCancelingOrder: true,
            };
        default:
            return state;
    }

}