import { AppThunkAction } from '.';

// Actions
import { KnownAction } from '../redux/Actions/OperatorActions';

// Functions
import * as Functions from '../redux/Functions/Commons';
import { IResult } from '../model/Result';
import { NewOperator, Operator } from '../model/operator';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/operarios';

export const actionCreators = {
  GetOperators: (): AppThunkAction<KnownAction> => async (dispatch) => {
    dispatch({
      type: 'REQUEST_ALL_OPERATORS'
    });

    await fetch(BaseURL)
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Operator[]>>;
        }
      })
      .then((data: IResult<Operator[]>) => {
        dispatch({
          type: 'RECEIVE_ALL_OPERATORS',
          operators: data.data
        });
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          dispatch({
            type: 'FAIL_GET_ALL_OPERATORS',
            Error: Functions.HttpErrorHandler(body, error)
          });
        })
      );
  },
  GetOperator:
    (OperatorId: number): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'REQUEST_OPERATOR',
        operatorId: OperatorId
      });

      await fetch(BaseURL + '/' + OperatorId)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<Operator>>;
          }
        })
        .then((data: IResult<Operator>) => {
          dispatch({
            type: 'RECEIVE_OPERATOR',
            operator: data.data
          });
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_GET_OPERATOR',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    },
  AddOperator:
    (newOperator: NewOperator): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'REQUEST_ADD_OPERATOR',
        newOperator: newOperator
      });

      await fetch(BaseURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(newOperator)
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<Operator>>;
          }
        })
        .then((data: IResult<Operator>) => {
          dispatch({
            type: 'RECEIVE_ADDED_OPERATOR',
            operator: data.data
          });
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_ADD_OPERATOR',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    },
  SaveOperator:
    (Operator: Operator): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: 'REQUEST_SAVE_OPERATOR',
        operator: Operator
      });

      await fetch(BaseURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(Operator)
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<Operator>>;
          }
        })
        .then((data: IResult<Operator>) => {
          dispatch({
            type: 'RECEIVE_SAVED_OPERATOR',
            operator: data.data
          });
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_SAVE_OPERATOR',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    }
};
