import { Action, Reducer } from 'redux';
import { KnownAction } from '../Actions/StockActions';
import { StockState, unloadedState } from '../States/StockState';

// functions
import * as Functions from '../Functions/Commons';

export const stockReducer: Reducer<StockState> = (
  state: StockState | undefined,
  incomingAction: Action
): StockState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_MEASUREMENT_UNITS':
      return {
        ...state,
        isLoadingMeasurementUnits: true,
        isLoadingMeasurementUnitsSuccess: undefined,
        FailGettingMeasurementUnits: false,
        Error: undefined
      };
    case 'RECEIVE_MEASUREMENT_UNITS':
      return {
        ...state,
        isLoadingMeasurementUnits: false,
        isLoadingMeasurementUnitsSuccess: true,
        FailGettingMeasurementUnits: false,
        Error: undefined,
        MeasurementUnits: action.MeasurementUnits
      };
    case 'FAIL_GET_MEASUREMENT_UNITS':
      return {
        ...state,
        isLoadingMeasurementUnits: false,
        isLoadingMeasurementUnitsSuccess: false,
        FailGettingMeasurementUnits: true,
        MeasurementUnits: [],
        Error: action.Error
      };
    case 'REQUEST_ADD_PRESENTATION':
      return {
        ...state,
        isAddingPresentation: true,
        isAddingPresentationSuccess: undefined,
        FailAddingPresentation: false,
        Error: undefined
      };
    case 'RECEIVE_ADDED_PRESENTATION':
      return {
        ...state,
        isAddingPresentation: false,
        isAddingPresentationSuccess: true,
        FailAddingPresentation: false,
        Error: undefined
      };
    case 'FAIL_ADD_PRESENTATION':
      return {
        ...state,
        isAddingPresentation: false,
        isAddingPresentationSuccess: false,
        FailAddingPresentation: true,
        Error: action.Error
      };
    case 'REQUEST_STOCK':
      return {
        ...state,
        isLoadingStock: true,
        isLoadingStockSuccess: undefined,
        FailGetStock: false,
        Error: undefined
      };
    case 'RECEIVE_STOCK':
      return {
        ...state,
        isLoadingStock: false,
        isLoadingStockSuccess: true,
        FailGetStock: false,
        Error: undefined,
        CurrentStock: action.Stock
      };
    case 'FAIL_GET_STOCK':
      return {
        ...state,
        isLoadingStock: false,
        isLoadingStockSuccess: false,
        FailGetStock: true,
        Error: action.Error
      };
    case 'REQUEST_ADD_STOCK_TRANSACTION':
      return {
        ...state,
        isAddingNewStockTransaction: true,
        isAddedStockTransactionSuccess: undefined,
        FailAddStockTransaction: false,
        Error: undefined
      };
    case 'RECEIVE_ADDED_STOCK_TRANSACTION':
      return {
        ...state,
        isAddingNewStockTransaction: false,
        isAddedStockTransactionSuccess: true,
        FailAddStockTransaction: false,
        Error: undefined
      };
    case 'FAIL_ADD_STOCK_TRANSACTION':
      return {
        ...state,
        isAddingNewStockTransaction: false,
        isAddedStockTransactionSuccess: false,
        FailAddStockTransaction: true,
        Error: action.Error
      };
  }

  return state;
};
