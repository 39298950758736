import { HeaderBarComponent } from '../HeaderBar/HeaderBarcomponent';
import { MenuComponent } from '../Menu/MenuComponent';

import './SideBarComponent.css';

export const SideBarComponent = () => {
  return (
    <div className="SideBarContent">
      <HeaderBarComponent />
      <div className="menuDistribution">
        <MenuComponent Group={1} />
        <MenuComponent Group={2} />
      </div>
    </div>
  );
};
