import { AuthenticatedUser, NewUser, User } from '../model/authenticate';
import { IResult } from '../model/Result';

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'api/Account';

// Authenticate User
export const AuthenticateUser = async (
  user: string,
  password: string
): Promise<AuthenticatedUser> => {
  const RequestURL: string = BaseURL + '/authenticate';

  try {
    const Response: AuthenticatedUser = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ email: user, password: password })
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AuthenticatedUser>>;
        }
      })
      .then((data: IResult<AuthenticatedUser>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw typeof error.text() === 'function'
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    throw error;
  }
};

// Renew authentication token
export const RenewToken = async (token: string): Promise<AuthenticatedUser> => {
  const RequestURL: string = BaseURL + '/renew';

  try {
    const Response: AuthenticatedUser = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AuthenticatedUser>>;
        }
      })
      .then((data: IResult<AuthenticatedUser>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw typeof error.text() === 'function'
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    throw error;
  }
};

// Update user info

export const UpdateUserInfo = async (
  user: User,
  token: String
): Promise<User> => {
  const RequestURL: string = BaseURL + '/update';

  try {
    const Response: User = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(user)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<User>>;
        }
      })
      .then((data: IResult<User>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw typeof error.text() === 'function'
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const ChangeUserPassWord = async (
  user: User,
  password: string,
  token: String
): Promise<boolean> => {
  const RequestURL: string = BaseURL + '/changepassword';

  try {
    const Response: boolean = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify({
        email: user.email,
        token: token,
        password: password,
        confirmPassword: password
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<boolean>>;
        }
      })
      .then((data: IResult<boolean>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw typeof error.text() === 'function'
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    throw error;
  }
};

// Get Users List
export const GetUsers = async (token: string): Promise<User[]> => {
  const RequestURL: string = BaseURL + '/users';

  try {
    const Response: User[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<User[]>>;
        }
      })
      .then((data: IResult<User[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw typeof error.text() === 'function'
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    throw error;
  }
};

// Add new User
export const RegisterUser = async (
  newUser: NewUser,
  token: string
): Promise<boolean> => {
  const RequestURL: string = BaseURL + '/register';

  try {
    const Response: boolean = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newUser)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<any>;
        }
      })
      .then((data: any) => {
        return true;
      })
      .catch((error: any) => {
        throw typeof error.text() === 'function'
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    throw error;
  }
};
