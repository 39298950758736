import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PasosFormProducto } from '../components/formula/pasosFormProducto';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../stores';
import ProductHeader from '../components/producto/Header/ProductHeader';

// stores
import * as FormulaStore from '../stores/Formula';
import * as ProductosStore from '../stores/Productos';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { NewFormula } from '../model/formula';

export const ProductoPage = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos
  );
  const FormulaState = useSelector((state: ApplicationState) => state.formulas);

  const [ComponentsErrors, setComponentsErrors] = useState<string[]>([]);

  useEffect(() => {
    dispatch(mapDispatchToProps.getProducto(Number(id)));
  }, []);

  useEffect(() => {
    if (
      FormulaState &&
      FormulaState.isAddingFormulaSuccess &&
      FormulaState.formula
    ) {
      dispatch(
        mapDispatchToProps.SetFormulaToSelectedProduct(FormulaState.formula)
      );
    }
  }, [FormulaState?.formula, FormulaState?.isAddingFormulaSuccess]);

  const OnCreateFormulaClickHandler = () => {
    if (ProductosState && ProductosState.producto) {
      const formula: NewFormula = {
        productoid: ProductosState.producto.id,
        observacion: ''
      };
      dispatch(mapDispatchToProps.AddFormulaToProduct(formula));
    }
  };

  const OnHandleErrorFormulaComponents = (Errors: string[]) => {
    setComponentsErrors(Errors);
  };

  return ProductosState?.producto ? (
    <>
      <div className="pageHeader">
        <ProductHeader producto={ProductosState?.producto!}></ProductHeader>
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      {ComponentsErrors.length > 0 && (
        <div className="ErrorContainer">
          <ul>
            {ComponentsErrors.map((error: string) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )}
      {!ProductosState?.producto.formula && (
        <div className="ErrorContainer">
          <ul>
            <li>
              El producto no tiene formula asociada&nbsp;
              <button
                className="btn btn-primary"
                onClick={OnCreateFormulaClickHandler}
              >
                GenerarFomula
              </button>
            </li>
          </ul>
        </div>
      )}
      {ProductosState?.producto.formula && (
        <PasosFormProducto
          FormulaId={Number(ProductosState?.producto.formula.id)}
          byAverage={true}
          OnErrorEvent={OnHandleErrorFormulaComponents}
        ></PasosFormProducto>
      )}
    </>
  ) : (
    <div className="loadingFlag">Cargando...</div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos,
  ...state.formulas
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators,
  ...ProductosStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductoPage as any);
