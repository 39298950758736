import * as React from 'react';
import '../../custom.css';
import { PTable } from '../helperTable';
import { Column } from 'react-table';
import { ListaFormula } from '../../model/lista_formula';
import { useHistory } from 'react-router-dom';
export const FormulaTable = (dataState: any) => {
  let data = new Array();
  if (dataState.dataState != undefined) {
    data = dataState.dataState;
  }
  let history = useHistory();
  const pasosFormula = (value: ListaFormula) => {
    history.push('formula/detalle/' + value.id);
  };

  const buttons = [
    { btnText: 'Detalle', btnFunc: pasosFormula, btnclass: 'btn btn-warning' }
  ];

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Id',
          accessor: 'id',
          isVisible: false
        },
        {
          Header: 'ProductoId',
          accessor: 'productoid',
          isVisible: false
        },
        {
          Header: 'Producto',
          accessor: 'producto.nombre'
        },
        {
          Header: 'Observacion',
          accessor: 'observacion'
        }
      ] as Column<ListaFormula>[],
    []
  );
  return (
    <React.Fragment>
      <div className="col-md-12 contentpage">
        <PTable
          columnas={columns}
          datos={data}
          hiddenColumns={['id', 'productoid']}
          buttons={buttons}
        />
      </div>
    </React.Fragment>
  );
};
