import { Remito } from "../../model/Remito/Remito";
import { CommonState } from "../../stores/common/commonState";

export interface RemitoState extends CommonState {
    candelingOrder: boolean;
    canceledOrderSuccessfuly: boolean | undefined;
    failOnCancelingOrder: boolean;
    list: Remito[];
  }
  
  export const remitoUnloadedState: RemitoState = {
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    isSaving: false,
    isSavingSuccess: undefined,
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,
    FailSaving: false,
    FailDeletingOne: false,
    FailGettingAll: false,
    FailGettingOne: false,
    isFiltered: false,
    filterCriteria: undefined,
    Error: undefined,
    list: [],

    candelingOrder: false,
    canceledOrderSuccessfuly: undefined,
    failOnCancelingOrder: false,
  };