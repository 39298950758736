import * as React from 'react';
import { useEffect } from 'react';
import * as FormulaStore from '../stores/Formula';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PasosFormProducto } from '../components/formula/pasosFormProducto';
import * as SuppliesStore from '../stores/Materia_Prima';
import * as ProductoStore from '../stores/Productos';
import { ApplicationState } from '../stores';
import { useHistory, useParams } from 'react-router-dom';

export const EditProductoPage = () => {
  const { id }: { id: string } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos?.producto
  );

  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
    dispatch(mapDispatchToProps.getProducto(Number(id)));
  }, []);

  return (
    <div className="col-md-12">
      <h1 className="ms-2">Editar Producto</h1>
      <div className="col-md-12">
        <button
          type="button"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.push('/producto');
          }}
        >
          Volver
        </button>
      </div>
      {/* <ProductoForm ProductSavedEvent={() => null} /> */}
      <>
        {ProductosState && ProductosState.formula ? (
          <PasosFormProducto
            FormulaId={ProductosState.formula.id!}
            byAverage={true}
          />
        ) : null}
      </>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos
});

const mapDispatchToProps = {
  ...SuppliesStore.actionCreators,
  ...FormulaStore.actionCreators,
  ...ProductoStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductoPage as any);
