import { IResult } from './../../model/Result';
import { ErrorType } from '../../model/FetchError';

// return query param object
export const GetQueryStringParams = (
  pageNumber?: number,
  pageSize?: number,
  searchText?: string
): URLSearchParams => {
  let paramsResult: URLSearchParams = new URLSearchParams();

  if (pageNumber && pageSize && searchText) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber),
      PageSize: String(pageSize),
      SearchText: searchText
    });
  }

  if (pageNumber && pageSize) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber),
      PageSize: String(pageSize)
    });
  }
  if (pageNumber && searchText) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber),
      SearchText: searchText
    });
  }
  if (pageSize && searchText) {
    paramsResult = new URLSearchParams({
      PageSize: String(pageSize),
      SearchText: searchText
    });
  }
  if (pageNumber) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber)
    });
  }
  if (pageSize) {
    paramsResult = new URLSearchParams({
      PageSize: String(pageSize)
    });
  }
  if (searchText) {
    paramsResult = new URLSearchParams({
      SearchText: searchText
    });
  }

  return paramsResult;
};

// process http error
export const HttpErrorHandler = (
  body: IResult<null> | null,
  response: any
): ErrorType => {
  let CurrentResponse: ErrorType;
  if (body) {
    console.log('body response', response);
    console.log('body body', body);
    var JsonBody = JSON.parse(body as any);
    CurrentResponse = {
      ErrorCode: JsonBody.statusCode,
      ErrorMessage: JsonBody.message || '',
      Errors: JsonBody.errors
    };
    console.log(CurrentResponse);
    return CurrentResponse;
  } else {
    console.log('no body response', response);
    console.log('no body body', body);
    CurrentResponse = {
      ErrorCode: response.status,
      ErrorMessage: response.statusText,
      Errors: response.errors || []
    };

    return ErrorDefinitionResponse(CurrentResponse);
  }
};

const ErrorDefinitionResponse = (error: ErrorType): ErrorType => {
  switch (error.ErrorCode) {
    case 401:
      error.ErrorMessage = 'No Autorizado';
      break;
    case 404:
      error.ErrorMessage = 'Servidor fuera de linea o Recurso no encontrado';
      break;
    case 409:
    case 400:
      error.ErrorMessage =
        'Error al procesar la solicitud, verifique la informacion suministrada';
      break;
    case 500:
      error.ErrorMessage =
        'Ocurrio un error inesperado al consultar el recurso';
      break;
  }

  return error;
};

export const DynamicSort = (property: any) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};
