import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Remito } from '../../../model/Remito/Remito';
import { Alert } from 'react-bootstrap';

// component functions
import * as ComponentFunctions from './RemitoList.Functions';
import RemitoFormComponent from '../Form/RemitoForm.Component';
import { set } from 'react-hook-form';
import moment from 'moment';



export const RemitoListComponent = (props: ComponentFunctions.Props) => {

    const [SearchText, setSearchText] = useState<string>('');
    const [showAlert, setShowAlert] = useState(false);
    const [selectedRemito, setSelectedRemito] = useState<Remito>();
    const [radioSelection, setRadioSelection] = useState<string>('REMITO_NUMBER'); // Estado para manejar la selección de los radio buttons

    // efects
    useEffect(() => {
        props.GetAll('0', '5000', '', '');
    }, []);

    //Buscar solo si posee texto almacenado en 'SearchText'
    useEffect(() => {
        if (SearchText !== '' || SearchText?.length > 0) {
            searchHandler();
        }

    }, [radioSelection]);

    //Carga nuevamente la lista de Remitos al borrarse o limpiarse el campo 'SearchText'.
    useEffect(() => {
        if (SearchText == '' || SearchText?.length === 0) {
            searchHandler();
        }
    }, [SearchText]);



    // events
    const pressEnterHandler = (e: any) => {
        return e.key === 'Enter' ? searchHandler() : e;
    };

    const searchHandler = () => {
        props.GetAll('0', '5000', SearchText, radioSelection);
    };

    const removeFilterHandler = () => {
        setSearchText('');
    };

    const deleteRemitoHandler = (remito: Remito) => {
        window.confirm('¿Está seguro que desea eliminar el remito: ' + remito.codigo + ' ?') && props.Delete(remito.id);
    };

    // Funcion para manejar los cambios en la selección de los radio buttons
    const handleRadioSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRadioSelection(e.target.value);
    };

    const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };


    const SearchBar = (
        <div className="searchBar">
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Ingrese el texto a buscar..."
                    className="form-control"
                    defaultValue=""
                    onKeyDown={pressEnterHandler}
                    onChange={handleSearchText}
                    value={SearchText}
                />
                {SearchText !== '' && (
                    <button
                        className="input-group-text"
                        id="basic-addon2"
                        onClick={removeFilterHandler}
                    >
                        X
                    </button>
                )}
            </div>

        </div>

    );
    // Radio button para manejar el filtrado correctamente.
    const RadioButtons = (
        <div className="">
            <div className="form-check form-check-inline mt-2">
                <input
                    type="radio"
                    id="remito_number"
                    value="REMITO_NUMBER"
                    checked={radioSelection === 'REMITO_NUMBER'}
                    onChange={handleRadioSelection}
                    className="form-check-input"
                />
                <label htmlFor="remito_number" className="form-check-label">Nro. Remito</label>
            </div>

            <div className="form-check form-check-inline">
                <input
                    type="radio"
                    id="provider"
                    value="PROVIDER"
                    checked={radioSelection === 'PROVIDER'}
                    onChange={handleRadioSelection}
                    className="form-check-input"
                />
                <label htmlFor="provider" className="form-check-label">Proveedor</label>
            </div>

            <div className="form-check form-check-inline">
                <input
                    type="radio"
                    id="material"
                    value="RAW_MATERIAL_CODE"
                    checked={radioSelection === 'RAW_MATERIAL_CODE'}
                    onChange={handleRadioSelection}
                    className="form-check-input"
                />
                <label htmlFor="material" className="form-check-label">Materia Prima</label>
            </div>
        </div>
    );



    const GettingSuccessfully = (
        <>
            <div>
                <h5>Remitos registrados</h5>
                <div className="seccion">
                    {SearchBar}
                    {RadioButtons}
                    <hr></hr>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Nro. Remito</th>
                                <th>Fecha de remito</th>
                                <th>Fecha de carga</th>
                                <th>Proveedor</th>
                                <th>Materia Prima</th>
                                <th>Cantidad</th>
                                <th>Precio/Kg</th>
                                <th>Precio Total</th>
                                <th>Comentarios</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.remitos?.filter((remito: Remito) => {
                                if (SearchText !== '') {
                                    if (radioSelection === 'PROVIDER') {
                                        return remito.proveedor.toLowerCase().includes(SearchText.toLowerCase());
                                    } else if (radioSelection === 'RAW_MATERIAL_CODE') {
                                        return remito.materiaPrima.codigo.toLowerCase().includes(SearchText.toLowerCase());
                                    } else {
                                        //Remito
                                        return (
                                            remito.codigo.toLowerCase().includes(SearchText.toLowerCase())
                                        );
                                    }
                                } else {
                                    return true;
                                }
                            })
                                .sort((a: Remito, b: Remito) =>
                                    moment(b.creado).valueOf() - moment(a.creado).valueOf()
                                ) // Ordenar por fecha de manera descendente
                                ?.map((remito: Remito) => (
                                    <tr>
                                        <td>
                                            <strong className="upper">{remito.codigo}</strong>
                                        </td>
                                        <td>
                                            {console.log(remito.fechaRemito)}
                                            <label className="upper">{moment(remito.fechaRemito).format('DD/MM/YYYY').toLocaleString()}</label>
                                        </td>
                                        <td>
                                            {console.log(remito.creado)}
                                            <label className="upper">{moment(remito.creado).format('DD/MM/YYYY').toLocaleString()}</label>
                                        </td>
                                        <td>
                                            <strong className="upper">{remito.proveedor}</strong>
                                        </td>
                                        <td>
                                            <label className="upper">{remito.materiaPrima.codigo}</label>
                                        </td>
                                        <td>
                                            <label className="upper">{remito.cantidad + ' Kg'}</label>
                                        </td>
                                        <td>
                                            <label className="upper">{remito.precioUnitario + ' u$d/kg'}</label>
                                        </td>
                                        <td>
                                            <label className="upper">{remito.precioTotal + ' u$d'}</label>
                                        </td>
                                        <td>
                                            <p>{remito.observaciones}</p>
                                        </td>
                                        <td>
                                            <div className="controls">
                                                <button
                                                    className="btn btn-outline-secondary btn-sm"
                                                    onClick={() => setSelectedRemito(remito)}
                                                >
                                                    Editar
                                                </button>
                                                <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={() => deleteRemitoHandler(remito)}
                                                >
                                                    Borrar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {props.remitos?.length === 0 && (
                        <label>No hay remitos registrados</label>
                    )}
                </div>
            </div>
        </>
    );

    const GettingFail = props.failOnLoading && props.error && (
        <div className="ErrorContainer">
            <label>{props.error?.ErrorMessage}</label>
            <ul>
                {props.error?.Errors.map((error: string) => (
                    <li>{error}</li>
                ))}
            </ul>
        </div>
    );

    const internalForms = (
        <RemitoFormComponent
            RemitoId={selectedRemito?.id}
            MateriaPrimaId={undefined}
            onSaveRemito={() => setSelectedRemito(undefined)}
            onCancel={() => setSelectedRemito(undefined)}
        />

    )

    return (
        <>
            <div className="contentpage">
                {(props.isFilteres && SearchText.length > 0) && (
                    <Alert variant="warning">
                        Filtro aplicado: <strong>{props.filterCriteria}</strong>
                    </Alert>
                )}
                {props.failOnDelete && showAlert && (
                    <Alert variant="danger" className="alert-dismissible stycky-top">
                        <label>Atención</label>
                        {props.error && (
                            <ul>
                                {props.error?.Errors?.map((error: string) => (
                                    <li>{error}</li>
                                ))}
                            </ul>
                        )}
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Cerrar"
                            onClick={() => setShowAlert(false)}
                        ></button>
                    </Alert>
                )}
                {props.LoadingSuccessfully && props.remitos && GettingSuccessfully}
                {props.failOnLoading && GettingFail}
                {selectedRemito && (
                    <div className="modalForm">
                        <h5>Información del remito</h5>
                        {internalForms}
                    </div>)}
            </div>
        </>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(RemitoListComponent as any);