import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { User } from '../../../model/authenticate';
import { ApplicationState } from '../../../stores';

//store

import * as AuthStore from '../../../stores/authentication';

export interface IProps {
  onEditUserEvent?: (user: User) => void;
  onchangePasswordEvent?: (user: User) => void;
}

export const UserListComponent = (props: IProps) => {
  const AuthState = useSelector(
    (AppState: ApplicationState) => AppState.authentication
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mapDispatchToProps.GetUsers());
  }, [dispatch]);

  //events
  const EditUserHandler = (user: User) => {
    if (props.onEditUserEvent) props.onEditUserEvent(user);
  };

  const ChangePasswordHandler = (user: User) => {
    if (props.onchangePasswordEvent) props.onchangePasswordEvent(user);
  };

  return (
    <div>
      <table className="table table=hover">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Opciones</th>
          </tr>
        </thead>
        {AuthState?.gettingUsersSuccess && (
          <tbody>
            {AuthState.users.map((user: User) => (
              <tr>
                <td>{user.userName}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>
                  <div className="controls">
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => EditUserHandler(user)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => ChangePasswordHandler(user)}
                    >
                      Cambiar contraseña
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas
});

const mapDispatchToProps = {
  ...AuthStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListComponent as any);
