import { FailDeleteSupply } from './../redux/Actions/SuppliesActions';
import { MateriaPrima, NewMateriaPrima } from './../model/materia_prima';
import { IResult } from '../model/Result';

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/materiasprimas/';

export const GetLowStockSupplies = async (token: string): Promise<MateriaPrima[]> => {
  const RequestURL: string = BaseURL + 'lowstock';
  console.log('RequestURL', RequestURL);
  try {
    const Response: MateriaPrima[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        console.log('response', response);
        if (!response.ok) {
          console.log('respuesta ok');
          throw response;
        } else {
          return response.json() as Promise<IResult<MateriaPrima[]>>;
        }
      })
      .then((data: IResult<MateriaPrima[]>) => {
        console.log('data', data);
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteSupply = async (
  supplyId: number,
  token: String
): Promise<boolean> => {
  const RequestURL: string = BaseURL + supplyId;

  try {
    const Response: boolean = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return true;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetAllSupplies = async (
  token: String,
  pageNumber?: number,
  pageSize?: number,
  text?: string
): Promise<MateriaPrima[]> => {
  const RequestURL: string = 
    BaseURL +
    '?' +
    Functions.GetQueryStringParams(pageNumber, pageSize, text);

  try {
    const Response: MateriaPrima[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MateriaPrima[]>>;
        }
      })
      .then((data: IResult<MateriaPrima[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetSupplyById = async (
  supplyId: number,
  token: String
): Promise<MateriaPrima> => {
  const RequestURL: string = BaseURL + supplyId;

  try {
    const Response: MateriaPrima = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MateriaPrima>>;
        }
      })
      .then((data: IResult<MateriaPrima>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const CreateSupply = async (
  supply: NewMateriaPrima,
  token: String
): Promise<MateriaPrima> => {
  const RequestURL: string = BaseURL;

  try {
    const Response: MateriaPrima = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(supply)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MateriaPrima>>;
        }
      })
      .then((data: IResult<MateriaPrima>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const UpdateSupply = async (
  supply: MateriaPrima,
  token: String
): Promise<MateriaPrima> => {
  const RequestURL: string = BaseURL + supply.id;

  try {
    const Response: MateriaPrima = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(supply)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MateriaPrima>>;
        }
      })
      .then((data: IResult<MateriaPrima>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
}
