// functions
import { connect } from 'react-redux';
import * as ComponentFunctions from './MateriaPrimaPicker.Functions';
import Select from 'react-select/dist/declarations/src/Select';
import { MateriaPrima } from '../../model/materia_prima';
import { useEffect } from 'react';


const MaterialPrimaPicker = (props: ComponentFunctions.Props) => {

    const def = { id: -1, codigo: '', nombre: 'Seleccione', descripcion: '' };

    // efects
    useEffect(() => {
        props.GetAllSupplies(1,5000,'');
    }, []);

    // events 
    const onChangeHandler = (e: any) => {
        props.OnSelectedSupplyChanged && props.OnSelectedSupplyChanged(props.supplies?.find((supply: MateriaPrima) => supply.id === Number(e.target.value)) || undefined);
    };

    return (
        <>
           <select
                className="form-control"
                onChange={onChangeHandler}
                value={props.selectedSupply?.id}
            >
            <option key={undefined} value={undefined}>{'Seleccione una materia prima'}</option>
            {props.supplies?.map((supply: MateriaPrima) => 
                <option key={supply.id} value={supply.id} selected={supply.id === props.SelectedSupplyId} >{supply.codigo.toUpperCase()} - {supply.nombre}</option>)}
            </select>
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(MaterialPrimaPicker as any);