import * as React from 'react';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ListaFormula } from '../../model/lista_formula';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as FormulaStore from '../../stores/Formula';
import { ItemPasos } from './itemPasos';
import { Pasos } from '../../model/pasos';
import { MateriaPrima } from '../../model/materia_prima';
import { ListaPasosDrag } from './listaPasosDrag';

//styles
import '../../custom.css';
import './formula.css';
import { ProSidebar } from 'react-pro-sidebar';

export interface IProps {
  FormulaId: number;
  ProductAmount?: number;
  byAverage: boolean;
  OnErrorEvent?: (Error: string[]) => void;
  OnchangecomponentsAmpount?: (amount: number) => void;
}

export const selectFormulaState = (state: ApplicationState) => state.formulas;

export const PasosFormOrden = (props: IProps) => {
  const dispatch = useDispatch();
  const [orden, setOrden] = React.useState(0);
  const FormulaState = useSelector((state: ApplicationState) => state.formulas);
  const [totalSuma, settotalSuma] = useState<number>(0);
  const [showForm, setshowForm] = useState<boolean>(false);
  const [componentsError, setcomponentsError] = useState<string[]>([]);

  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos?.producto
  );
  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  useEffect(() => {
    if (ProductosState) {
      dispatch(
        mapDispatchToProps.setPasosFormula(
          ProductosState.formula.lista_elementos
        )
      );
      setOrden(ProductosState.formula.lista_elementos[ProductosState.formula.lista_elementos.length - 1]?.orden!);
      reset(ProductosState.formula);
    }
  }, [ProductosState]);

  useEffect(() => {
    let valorSuma: number = 0;
    if (FormulaState?.pasos) {
      console.log('valor inicial suma', valorSuma);
      FormulaState?.pasos.map((paso: Pasos) => {
        console.log('Paso_Valor', Number(paso.valor!));
        if (!paso.pausa) valorSuma += Number(paso.valor!);
        console.log('Paso_ValorSuma', Number(valorSuma.toFixed(2)));
        return true;
      });
    }
    settotalSuma(Number(valorSuma.toFixed(2)));
    if (props.OnchangecomponentsAmpount)
      props.OnchangecomponentsAmpount(valorSuma);
  }, [FormulaState?.pasos]);

  useEffect(() => {
    let CurrentErros: string[] = [];
    if (totalSuma > 0) {
      if (props.byAverage) {
        if (totalSuma < 100)
          CurrentErros = [
            ...CurrentErros,
            'La suma de los componentes debe sumar 100%, porcentaje actual: ' +
              totalSuma +
              '%'
          ];
        if (totalSuma > 100)
          CurrentErros = [
            ...CurrentErros,
            'La suma de los componentes debe sumar 100%, porcentaje actual: ' +
              totalSuma +
              '%'
          ];
      } else {
        if (props.ProductAmount) {
          if (totalSuma < props.ProductAmount)
            CurrentErros = [
              ...CurrentErros,
              'La suma de los componentes actualmente es: ' +
                totalSuma +
                'Kg y tiene que sumar: ' +
                props.ProductAmount +
                'Kg'
            ];
          if (totalSuma > props.ProductAmount)
            CurrentErros = [
              ...CurrentErros,
              'La suma de los componentes actualmente es ' +
                totalSuma +
                'Kg y tiene que sumar ' +
                props.ProductAmount +
                'Kg'
            ];
        }
      }
    }
    setcomponentsError(CurrentErros);
  }, [totalSuma, props.ProductAmount]);

  useEffect(() => {
    if (props.OnErrorEvent) props.OnErrorEvent(componentsError);
  }, [componentsError]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<ListaFormula>({});
  //const onSubmit = handleSubmit(data => console.log(data));
  const onSubmit = (data: any) => {};

  const addPaso = (paso: Pasos) => {
    SuppliesState?.Supplies?.map((materia: MateriaPrima) => {
      if (paso.materiaprima && paso.materiaprima.id == materia.id) {
        paso.materiaprima = materia;
      }
    });
    if(paso.id < 0) { 
      paso.orden = orden + 1;
      paso.orden && setOrden(paso.orden);
    }
    console.log(paso);
    if (!paso.isEdicion) {
      dispatch({ type: 'ADD_PASO', paso: paso });
    } else {
      dispatch(mapDispatchToProps.updatePasoOrden(paso));
      //  dispatch({ type: "UPDATE_PASOS_ORDEN", paso: paso });
    }
    setshowForm(false);
  };
  const eliminarPaso = (value: Pasos, index: number) => {
    dispatch({ type: 'DELETE_PASO_ORDEN', position: index });
  };
  const editPaso = (value: Pasos, index: number) => {
    console.log('paso que va a modificar', value);
    value.isEdicion = true;
    value.position = index;
    // console.log(value);
    dispatch({ type: 'SET_PASO', paso: value });
    setshowForm(true);
  };

  const cancelPaso = () => {
    dispatch({
      type: 'SET_PASO',
      paso: {
        id: -1,
        formulaid: -1,
        cantidad: 0,
        pausa: false,
        materiaprima: { id: -1 },
        minutospausa: 0,
        observacion: ''
      }
    });
    setshowForm(false);
  };

  const AddFormulaComponentClickHandler = () => {
    setshowForm(true);
  };

  const updateLista = (value: Pasos[]) => {
    let i = 0;
    value.map((v: Pasos) => {
      v.orden = i;
      i++;
    });

    dispatch({ type: 'SET_PASOS', pasos: value });
  };
  const buttons = [
    {
      btnText: 'Eliminar',
      btnFunc: eliminarPaso,
      btnclass: 'btn btn-outline-danger'
    },
    {
      btnText: 'Editar',
      btnFunc: editPaso,
      btnclass: 'btn btn-outline-secondary'
    }
  ];
  return (
    <React.Fragment>
      <div>
        <h5>
          Fórmula{' '}
          <button
            className="btn btn-primary btn-sm"
            onClick={AddFormulaComponentClickHandler}
          >
            Agregar componente
          </button>
        </h5>
        <label>
          Total componentes:{' '}
          <strong>
            {totalSuma} {props.byAverage ? '%' : 'Kg.'}
          </strong>
        </label>
        {componentsError.length > 0 && (
          <div className="ErrorContainer">
            <ul>
              {componentsError?.map((error: string) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        )}
        {showForm && (
          <>
            <div className="PasosForm">
              <ItemPasos
                FormulaId={props.FormulaId}
                AddEvent={addPaso}
                CancelEvent={cancelPaso}
                isProducto={false}
              />
            </div>
          </>
        )}
        <ListaPasosDrag
          data={FormulaState?.pasos}
          update={updateLista}
          buttons={buttons}
          isProducto={false}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasosFormOrden as any);
