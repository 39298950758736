import * as React from 'react';
import { useEffect, useRef } from 'react';

import { useForm, Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import { MateriaPrima } from '../../model/materia_prima';
import { Pasos } from '../../model/pasos';
import Select from 'react-select';

//Store
import * as FormulaStore from '../../stores/Formula';
import * as SuppliesStore from '../../stores/Materia_Prima';

//styles
import '../../custom.css';
import './formula.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export interface IProps {
  FormulaId: number;
  AddEvent: (paso: Pasos) => void;
  CancelEvent: () => void;
  isProducto: boolean;
  RemainginPercentage?: number;
}

export const ItemPasos = (props: IProps) => {
  const dispatch = useDispatch();
  const MateriasPrimasRef = useRef();

  const allMethods = useForm();
  const { setFocus } = allMethods;

  const FormulaState = useSelector(
    (state: ApplicationState) => state.formulas?.paso
  );
  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  const [checked, setChecked] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  }, []);

  useEffect(() => {
    if (FormulaState) {
      reset(FormulaState);
      setChecked(FormulaState.pausa);
      setError(false);
    }
  }, [FormulaState]);

  const onFocusInputHandler = (e: any) => {
    e.target.select();
  };

  const defaultValues = {
    id: -1,
    formulaid: props.FormulaId,
    materiaprimaid: -1,
    pausa: false,
    minutospausa: 15,
    cantidad: 0,
    orden: 1,
    observacion: ''
  };

  const {
    register: itemRegister,
    reset,
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Pasos>({ defaultValues: defaultValues });
  const onSubmit = (data: any) => {
    setValue('orden', data.orden);
    setError(false);
    if (!getValues('pausa'))
      if (
        !getValues('materiaprima.id') ||
        getValues('materiaprima.id') === -1 ||
        (!props.isProducto && ((getValues('valor') !== undefined && props.RemainginPercentage !== undefined) && getValues('valor')! > props.RemainginPercentage))
      ) {
        setError(true);
        return;
      }

    getData(data as Pasos);
  };
  const def = { id: -1, codigo: '', nombre: 'Seleccione', descripcion: '' };
  const getData = (paso?: Pasos) => {
    var newPaso = {
      //  materiaprima: materiasPrimas.find((c) => c.id === getValues("materiaprima.id")),
      materiaprimaid: getValues('materiaprima.id'),
      materiaprima: getValues('materiaprima'),
      observacion: getValues('observacion'),
      formulaid: props.FormulaId,
      valor: !getValues('pausa') ? getValues('valor') : undefined,
      pausa: getValues('pausa'),
      minutospausa: getValues('pausa') ? getValues('minutospausa') : undefined,
      id: getValues('id'),
      isEdicion: FormulaState?.isEdicion,
      position: FormulaState?.position,
      orden: paso?.orden
    };
    props.AddEvent(newPaso);
    reset(defaultValues);
  };

  const addPausa = (minutes: number) => {
    setChecked(checked);
    setValue('formulaid', props.FormulaId);
    setValue('minutospausa', minutes);
    setValue('valor', undefined);
    setValue('observacion', '');
    setValue('pausa', true);
    getData();
  };
  return (
    <>
      <form className="formulaForm" key={2} onSubmit={handleSubmit(onSubmit)}>
        <h4>Información de componente</h4>
        {!FormulaState?.isEdicion && (
          <DropdownButton title="Agregar Dispersión" id="bg-nested-dropdown">
            <Dropdown.Item
              eventKey="1"
              onClick={() => {
                addPausa(10);
                // setChecked(!checked);
              }}
            >
              10 minutos
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              onClick={() => {
                addPausa(15);
                // setChecked(!checked);
              }}
            >
              15 minutos
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                addPausa(30);
                // setChecked(!checked);
              }}
            >
              30 minutos
            </Dropdown.Item>
          </DropdownButton>
        )}
        <hr />
        <div>
          <Form.Label>Materias Primas</Form.Label>
          <Controller
            control={control}
            name="materiaprima"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                options={SuppliesState?.RawMaterials}
                isDisabled={checked}
                defaultValue={def}
                value={SuppliesState?.RawMaterials?.filter(
                  (option: MateriaPrima) =>
                    option.id === getValues('materiaprima.id')
                )}
                placeholder="Seleccione..."
                getOptionLabel={(materiasPrimas) =>
                  materiasPrimas.codigo && materiasPrimas.nombre
                    ? materiasPrimas.codigo + ' - ' + materiasPrimas.nombre
                    : materiasPrimas.codigo
                }
                getOptionValue={(materiasPrimas) =>
                  materiasPrimas.id.toString()
                }
                onChange={(val: any) => onChange(val)}
              />
            )}
          />
          {error ? 'La materia prima es requerida.' : null}
        </div>

        {!checked ? (
          props.isProducto ? (
            <div>
              <Form.Label>
                {props.isProducto ? 'Porcentaje' : 'Kilogramos'}
              </Form.Label>
              <Form.Control
                disabled={checked}
                type="number"
                placeholder={props.isProducto ? '%' : 'Kg'}
                min="0.001"
                step=".001"
                className="form-control"
                {...itemRegister('valor', {
                  required: !checked,
                  min: checked ? 0 : 0.001,
                  max: props.RemainginPercentage
                })}
                onFocus={onFocusInputHandler}
              />
              {errors.valor?.type === 'required' &&
                (props.isProducto
                  ? 'El porcentaje es requerido'
                  : 'Los kilogramos son requerida')}
              {errors.valor?.type === 'max' &&
                ((props.isProducto && props.RemainginPercentage) && getValues('valor')! > props.RemainginPercentage && 'El porcentaje no puede superar el ' + props.RemainginPercentage + '%')}
            </div>
          ) : (
            <div className="">
              <Form.Label>
                {props.isProducto ? 'Porcentaje' : 'Kilogramos'}
              </Form.Label>
              <Form.Control
                disabled={checked}
                type="number"
                placeholder={props.isProducto ? '%' : 'Kg'}
                min="0.001"
                step=".001"
                className="form-control"
                {...itemRegister('valor', {
                  required: !checked,
                  min: checked ? 0 : 0.001
                })}
              />
              {errors.valor?.type === 'required' &&
                (props.isProducto
                  ? 'El porcentaje es requerido'
                  : 'Los kilogramos son requerida')}

            </div>
          )
        ) : (
          <div className="">
            <Form.Label>Minutos</Form.Label>
            <input
              type="number"
              placeholder="Cant."
              min="1"
              max="99"
              className="form-control"
              {...itemRegister('minutospausa', {
                required: checked,
                min: 1,
                max: 99,
                maxLength: 2,
                minLength: 1
              })}
            />
            {errors.minutospausa?.type === 'required' &&
              'Los minutos son requeridos'}
            {errors.minutospausa?.type === 'max' &&
              'Los minutos no pueden superar los 99'}
          </div>
        )}

        {/* <Form.Label>Orden</Form.Label>
        <Form.Control
          disabled={checked}
          type="number"
          placeholder="orden"
          min="0"
          step="1"
          className="form-control"
          {...itemRegister('orden', {
            required: true,
            min: 0
          })}
          onFocus={onFocusInputHandler}
        />
        {errors.orden?.type === 'required' && 'El orden es requerido'} */}

        {/* <div className="col-md-1 mb-2 mt-2">
            <Form.Label></Form.Label>
            <Form.Check
              label="Pausa"
              checked={checked}
              onClick={() => {
                addPausa(15);
                // setChecked(!checked);
              }}
              {...itemRegister('pausa', {
                required: false
              })}
            />
            {errors.pausa?.type === 'required' &&
              (props.isProducto
                ? 'El porcentaje es requerido'
                : 'Los kilogramos son requerida')}
          </div> */}
        {/* {checked ? (
              <div className="col-md-1 mb-2">
                <Form.Label>Minutos</Form.Label>
                <input
                  type="number"
                  placeholder="Cant."
                  min="1"
                  max="99"
                  className="form-control"
                  {...itemRegister('minutospausa', {
                    required: checked,
                    min: 1,
                    max: 99,
                    maxLength: 2,
                    minLength: 1
                  })}
                />
                {errors.minutospausa?.type === 'required' &&
                  'Los minutos son requeridos'}
                {errors.minutospausa?.type === 'max' &&
                  'Los minutos no pueden superar los 99'}
              </div>
            ) : null} */}

        <div>
          <Form.Label>Observaciones</Form.Label>
          <Form.Control
            id="ob"
            type="text"
            placeholder="Observacion"
            className="form-control"
            {...itemRegister('observacion', {
              required: false,
              maxLength: 250,
              minLength: 3
            })}
          />
          {errors.observacion?.type === 'required' &&
            'La descripcion es requerida'}
          {errors.observacion?.type === 'minLength' &&
            'La descripcion es muy chica'}
          {errors.observacion?.type === 'maxLength' &&
            'La descripcion es muy grande'}
        </div>
        <hr />
        <div className="formControls">
          <button type="submit" className="btn btn-primary">
            Confirmar
          </button>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              reset(defaultValues);
              setChecked(false);
              setError(false);
            }}
          >
            Limpiar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              props.CancelEvent();
            }}
          >
            Cancelar
          </button>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas,
  ...state.Supplies
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators,
  ...SuppliesStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemPasos as any);
