// actions 
import { Action, Reducer } from 'redux';
import * as Actions from '../Actions/RemitoActions';
import { RemitoState, remitoUnloadedState } from '../States/RemitoState';


type KnownAction = 
    | Actions.Remito_GetAll_Request_Action
    | Actions.Remito_GetAll_Success_Action
    | Actions.Remito_GetAll_Fail_Action
    | Actions.Remito_Create_Request_Action
    | Actions.Remito_Create_Success_Action
    | Actions.Remito_Create_Fail_Action
    | Actions.Remito_Get_Request_Action
    | Actions.Remito_Get_Success_Action
    | Actions.Remito_Get_Fail_Action
    | Actions.Remito_Update_Request_Action
    | Actions.Remito_Update_Success_Action
    | Actions.Remito_Update_Fail_Action
    | Actions.Remito_Delete_Request_Action
    | Actions.Remito_Delete_Success_Action
    | Actions.Remito_Delete_Fail_Action;

export const RemitoReducer: Reducer<RemitoState> = (state: RemitoState | undefined, incomingAction: Action): RemitoState  => {

    if (state === undefined) {
        return remitoUnloadedState as RemitoState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'REMITO_GETALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                isLoadingAllSuccess: undefined,
                FailGettingAll: false,
                Error: undefined,
                isFiltered: action.isFiltered,
                filterCriteria: action.filterCriteria
            };
        case 'REMITO_GETALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                isLoadingAllSuccess: true,
                FailGettingAll: false,
                list: action.remitos.sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REMITO_GETALL_FAIL':
            return {
                ...state,
                isLoadingAll: false,
                isLoadingAllSuccess: false,
                FailGettingAll: true,
                Error: action.Error
            };
        case 'REMITO_CREATE_REQUEST':
            return {
                ...state,
                isAdding: true,
                isAddingSuccess: undefined,
                FailAdding: false,
                Error: undefined
            };
        case 'REMITO_CREATE_SUCCESS':
            return {
                ...state,
                isAdding: false,
                isAddingSuccess: true,
                FailAdding: false,
                list: [...state.list, action.remito].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REMITO_CREATE_FAIL':
            return {
                ...state,
                isAdding: false,
                isAddingSuccess: false,
                FailAdding: true,
                Error: action.Error
            };
        case 'REMITO_GET_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                isLoadingOneSuccess: undefined,
                FailGettingOne: false,
                Error: undefined
            };
        case 'REMITO_GET_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                isLoadingOneSuccess: true,
                FailGettingOne: false,
                list: [...state.list.filter((remito) => remito.id !== action.remito.id), action.remito].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REMITO_GET_FAIL':
            return {
                ...state,
                isLoadingOne: false,
                isLoadingOneSuccess: false,
                FailGettingOne: true,
                Error: action.Error
            };
        case 'REMITO_UPDATE_REQUEST':
            return {
                ...state,
                isSaving: true,
                isSavingSuccess: undefined,
                FailSaving: false,
                Error: undefined
            };
        case 'REMITO_UPDATE_SUCCESS':
            return {
                ...state,
                isSaving: false,
                isSavingSuccess: true,
                FailSaving: false,
                list: [...state.list.filter((remito) => remito.id !== action.remito.id), action.remito].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REMITO_UPDATE_FAIL':
            return {
                ...state,
                isSaving: false,
                isSavingSuccess: false,
                FailSaving: true,
                Error: action.Error
            };
        case 'REMITO_DELETE_REQUEST':
            return {
                ...state,
                isDeletingOne: true,
                isDeletingOneSuccess: undefined,
                FailDeletingOne: false,
                Error: undefined
            };
        case 'REMITO_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                isDeletingOneSuccess: true,
                FailDeletingOne: false,
                list: [...state.list.filter((remito) => remito.id !== action.remitoId)].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REMITO_DELETE_FAIL':
            return {
                ...state,
                isDeletingOne: false,
                isDeletingOneSuccess: false,
                FailDeletingOne: true,
                Error: action.Error
            };
        default:
            return state;
    }

}