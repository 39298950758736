import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../stores';
// stores
import * as OrdenesFabricacionStore from '../stores/OrdenFabricacion';

import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { OrdenFabricacionHeader } from '../components/orden/Header/OrdenFabricacionHeader';
import { OrderVerificationFormComponent } from '../components/orden/OrderVerificationForm/OrderVerificationFormComponent';
import { OrderAdditionalComponentList } from '../components/orden/OrdenAditionalComponentForm/OrderAdditionalComponentList';
import { TagsFormComponent } from '../components/orden/TagsForm/TagsForm';
import { OrderVerification } from '../model/finalizarOrden';
import { AdditionalRawMaterial } from '../model/AdditionalRawMaterial';
import { OrdenFabricacionFormulaComponent } from '../components/orden/widgets/OrdenFabricacionFornula/OrdenFabicacionFormulaComponent';
import { OrdenFabricacionAdicionalesComponent } from '../components/orden/widgets/OrdenFabricacionAdiconales/OrdenFabricacionAdicionalesComponent';
import CancelButtonComponent from '../components/orden/CancelButtonComponent/CancelButton.Component';
import moment from 'moment';

export const OrdenFabricacionPage = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [litresManufatured, setLitresManufactured] = useState<number>(0);
  const [comentarios, setComentarios] = useState<string>();
  const [showFinalizeOrderForm, setShowFinalizedOrderForm] =
    useState<boolean>(false);

  // form const
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [errorsOnForm, setErrorsOnForm] = useState<string[]>([]);
  const [errorOnTag, setErrorOnTag] = useState<boolean>();
  const [totalErrors, setTotalErrors] = useState<string[]>([]);
  const [finalizeOrderData, setFinalizeOrderData] =
    useState<OrderVerification>();

  const OrdenesFabricacionState = useSelector(
    (state: ApplicationState) => state.ordenes
  );

  const [additionalRawMaterial, setAdditionalRawMaterial] = useState<
    AdditionalRawMaterial[]
  >([]);

  useEffect(() => {
    dispatch(mapDispatchToProps.SetOrder(Number(id)));
  }, [id, dispatch]);

  useEffect(() => {
    if (
      isSubmitted &&
      !OrdenesFabricacionState?.isFinalizingOrder &&
      OrdenesFabricacionState?.isFinalizedSuccess
    )
      setShowFinalizedOrderForm(false);
  }, [OrdenesFabricacionState?.isFinalizingOrder]);

  const FinalizeOrderRequestedHandler = () => {
    setIsSubmitted(true);

    let valores: OrderVerification | undefined = finalizeOrderData;

    valores!.comentariosFinales = comentarios || '';

    console.log('Cantidad de errores', totalErrors.length);
    console.log('Orden Fabricacion', OrdenesFabricacionState?.orden);
    console.log('Data', valores);

    if (
      totalErrors.length === 0 &&
      OrdenesFabricacionState?.orden &&
      finalizeOrderData
    ) {
      console.log('Finaliza');
      dispatch(
        mapDispatchToProps.finalizarOrden(
          OrdenesFabricacionState.orden.id,
          valores!
        )
      );
      console.log('Adicionales', additionalRawMaterial);
      if (additionalRawMaterial.length > 0) {
        additionalRawMaterial.forEach((item: AdditionalRawMaterial) => {
          dispatch(mapDispatchToProps.AddAdditionalRawMaterial(item));
        });
      }
    }
  };

  const ComentariosChangeHandler = (event: any) => {
    setComentarios(event.target.value);
  };

  const FinalizeOrderClickHandler = () => {
    setIsSubmitted(false);
    setShowFinalizedOrderForm(true);
  };

  const ErrorOnFormHandler = (errors: string[]) => {
    console.log('Error on form', errors);
    setErrorsOnForm(errors);
    setTotalErrors(errors);
    if (errorOnTag)
      setTotalErrors([...errors, 'Verifique los litros envasados']);
  };

  const ErrorOnTagStatusChange = (status: boolean) => {
    setErrorOnTag(status);
    setTotalErrors(errorsOnForm);
    if (status)
      setTotalErrors([...errorsOnForm, 'Verifique los litros envasados']);
  };

  return OrdenesFabricacionState?.orden ? (
    <>
      <div className="pageHeader">
        <OrdenFabricacionHeader orden={OrdenesFabricacionState?.orden!} />
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      <div className="orderDetailsContainer">
        <div>
          {OrdenesFabricacionState && OrdenesFabricacionState?.orden.formula && (
            <>
              <div className="widgetSection">
                <div>
                  <h5>Cliente</h5>
                  <h3>{OrdenesFabricacionState?.orden?.codigoCliente?.toUpperCase()}</h3>
                </div>
                <hr/>
                <div>
                  <h5>Formula utilizada</h5>
                  <OrdenFabricacionFormulaComponent
                    formula={OrdenesFabricacionState?.orden.formula}
                  />
                </div>
                <div>
                  <h5>Matriales Adicionales</h5>
                  <OrdenFabricacionAdicionalesComponent
                    RawMaterial={
                      OrdenesFabricacionState?.orden.lista_adicionales
                    }
                  />
                </div>
                <div>
                  <h5>Observaciones</h5>
                  <p>{OrdenesFabricacionState?.orden.comentariosIniciales}</p>
                  <p>{OrdenesFabricacionState?.orden.comentariosFinales}</p>
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="OrdenEstadoFinalización">
            <label>Fecha de fabricación</label>
            <h5>{moment(OrdenesFabricacionState?.orden.fechaorden).format('DD/MM/YYYY hh:mm a').toLocaleString()}</h5>
            <hr />
            <a
              className="btn btn-primary"
              href={
                process.env.REACT_APP_API_ENDPOINT +
                'v1/ordenesfabricaciones/' +
                OrdenesFabricacionState?.orden?.id +
                '/report'
              }
              target="_blank"
              rel="noreferrer"
            >
              Imprimir Orden de fabricación
            </a>
            <hr />
            <div className="Fabricaciones">
              <h6>Cantidad de Fabricaciones</h6>
              <label>
                {OrdenesFabricacionState?.orden.cantidadFabricaciones}
              </label>
            </div>
            <label>Estado de Fabricación</label>
            {!OrdenesFabricacionState?.orden.finalizado ? (
              <h4>EN CURSO</h4>
            ) : (
              <h4>FINALIZADO</h4>
            )}
            {OrdenesFabricacionState?.orden.cancelado ?
             <div className="badge text-bg-secondary">ANULADA</div>
             : OrdenesFabricacionState?.orden.finalizado &&
              (OrdenesFabricacionState?.orden.aprobado ? (
                <div className="badge badge-green">APROBADO</div>
              ) : (
                <div className="badge badge-red">DESAPROBADO</div>
              ))}
            {OrdenesFabricacionState?.orden.finalizado && (
              <div className="text-left">
                <hr />
                <h6>
                  <strong>Gloss </strong>
                  {OrdenesFabricacionState?.orden.resultado_brillo}
                </h6>
                <h6>
                  <strong>Densidad </strong>
                  {OrdenesFabricacionState?.orden.resultado_densidad}
                </h6>
                <hr />
                <h6>
                  <strong>Cantidad solicitada </strong>
                  {OrdenesFabricacionState?.orden.cantidad} Kg
                </h6>
                <h6>
                  <strong>Litros Esperados </strong>
                  {OrdenesFabricacionState?.orden.litros_esperados} lts
                </h6>
                <h6>
                  <strong>Litros fabricados </strong>
                  {OrdenesFabricacionState?.orden.litros_fabricados} lts
                </h6>
                <hr />
                <h6>
                  <strong>Operario </strong>
                  <p>
                    {OrdenesFabricacionState?.orden.operario?.apellido},{' '}
                    {OrdenesFabricacionState?.orden.operario?.nombre}
                  </p>
                </h6>
              </div>
            )}
          </div>
          {!OrdenesFabricacionState?.orden.finalizado && (
            <>
            <button
              className="btn btn-primary"
              onClick={FinalizeOrderClickHandler}
            >
              Realizar verificación
            </button>
            < hr />
            < hr />
            <CancelButtonComponent orderId={OrdenesFabricacionState?.orden.id} isCanceled={OrdenesFabricacionState?.orden.cancelado || false} />
            </>
          )}
        </div>
      </div>
      {showFinalizeOrderForm && (
        <div className="modalForm large">
          {totalErrors.length > 0 && isSubmitted && (
            <div className="ErrorContainer">
              <ul>
                {totalErrors.map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}
          {OrdenesFabricacionState?.FailFinalizeOrder &&
            OrdenesFabricacionState?.Error && (
              <div className="ErrorContainer">
                <label>{OrdenesFabricacionState?.Error.ErrorMessage}</label>
                <ul>
                  {OrdenesFabricacionState?.Error.Errors?.map(
                    (error: string, index: number) => (
                      <li key={index}>{error}</li>
                    )
                  )}
                </ul>
              </div>
            )}
          <div className="formSections">
            <div>
              <label className="SubContentTitle">
                Datos de la verificación
              </label>
              <OrderVerificationFormComponent
                isSubmitted={isSubmitted}
                OnChangeLitresManufactured={(
                  actualLitresManufactured: number
                ) => setLitresManufactured(actualLitresManufactured)}
                OnErrorsDetected={ErrorOnFormHandler}
                OnFormDataChange={(data: OrderVerification) =>
                  setFinalizeOrderData(data)
                }
              />
            </div>
            <div>
              <label className="SubContentTitle">Materiales adicionales</label>
              <OrderAdditionalComponentList
                OrderId={OrdenesFabricacionState.orden.id}
                AdditionalRawMaterials={
                  OrdenesFabricacionState.orden.lista_adicionales
                }
                onAddAdditionalRawMaterial={(
                  newAdditionalRawMaterial: AdditionalRawMaterial
                ) =>
                  setAdditionalRawMaterial([
                    ...additionalRawMaterial,
                    newAdditionalRawMaterial
                  ])
                }
              />
              <hr />
              <label className="SubContentTitle">Envasado y Etiquetado</label>
              <TagsFormComponent
                OrderId={OrdenesFabricacionState.orden.id}
                TotalPackagedLitres={litresManufatured}
                ErrorStatusChange={ErrorOnTagStatusChange}
              />
            </div>
            <div className="NombreProducto" style={{ width: '100%' }}>
              <label className="form-label">
                Comentarios <span className="text-danger">*</span>
              </label>
              <textarea
                style={{ width: '100%' }}
                title="Comentarios"
                onChange={ComentariosChangeHandler}
              />
            </div>
          </div>
          <div className="formButtons">
            <button
              className="btn btn-primary"
              onClick={FinalizeOrderRequestedHandler}
            >
              Confirmar
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setShowFinalizedOrderForm(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="loadingFlag">Cargando...</div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.ordenes
});

const mapDispatchToProps = {
  ...OrdenesFabricacionStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdenFabricacionPage as any);
