import { useState } from 'react';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { OperatorFormComponent } from '../components/Operators/Form/OperatorFormComponent';
import { OperatorListComponent } from '../components/Operators/List/OperatorListComponent';
import { UserChangePasswordFormComponent } from '../components/userManager/UserForm/UserChangePasswordFormComponent';
import { UsersFormComponent } from '../components/userManager/UserForm/UserFormComponent';
import { UserListComponent } from '../components/userManager/UserList/UserListComponent';
import { User } from '../model/authenticate';
import { Operator } from '../model/operator';

export const SettingsPage = () => {
  const history = useHistory();

  // operators
  const [showOperatorForm, setShowOperatorForm] = useState<boolean>(false);
  const [editedOperator, setEditedOperator] = useState<Operator>();

  // Users
  const [showUserForm, setShowUserForm] = useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<User>();
  const [showchangePasswordForm, setShowChangePasswordForm] =
    useState<boolean>(false);

  //events
  const onEditOperatorEventHandler = (operator: Operator) => {
    setEditedOperator(operator);
    setShowOperatorForm(true);
  };

  const onAddOperatorEventHandler = () => {
    setEditedOperator(undefined);
    setShowOperatorForm(true);
  };

  const onOperatorSavedEventHandler = (operator: Operator) => {
    setEditedOperator(undefined);
    setShowOperatorForm(false);
  };

  const onOperatorFormCancelHandler = () => {
    setEditedOperator(undefined);
    setShowOperatorForm(false);
  };

  // user events

  const onEditUserEventHandler = (user: User) => {
    setEditedUser(user);
    setShowUserForm(true);
  };

  const onAddUserEventHandler = () => {
    setEditedUser(undefined);
    setShowUserForm(true);
  };

  const onUserSavedEventHandler = (user: User) => {
    setEditedUser(undefined);
    setShowUserForm(false);
  };

  const onUserFormCancelHandler = () => {
    setEditedUser(undefined);
    setShowUserForm(false);
  };

  const onUserChangePassword = (user: User) => {
    setEditedUser(user);
    setShowChangePasswordForm(true);
  };

  const onUserChangedPassword = () => {
    setEditedUser(undefined);
    setShowChangePasswordForm(false);
  };

  const onCancelUserChangedPassword = () => {
    setEditedUser(undefined);
    setShowChangePasswordForm(false);
  };

  return (
    <>
      <div className="pageHeader">
        <h2>Configuraciones</h2>
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      <div className="contentpage">
        <div>
          <label className="SubContentTitle">Operarios</label>
        </div>
        <div className="seccion">
          <button
            className="btn btn-primary"
            onClick={onAddOperatorEventHandler}
          >
            Agregar operador
          </button>
          <hr />
          <OperatorListComponent
            onOperatorEditEvent={onEditOperatorEventHandler}
          />
        </div>
        <div>
          <label className="SubContentTitle">Usuarios</label>
        </div>
        <div className="seccion">
          <button className="btn btn-primary" onClick={onAddUserEventHandler}>
            Agregar usuario
          </button>
          <hr />
          <UserListComponent
            onEditUserEvent={onEditUserEventHandler}
            onchangePasswordEvent={onUserChangePassword}
          />
        </div>
      </div>
      {showOperatorForm && (
        <div className="modalForm">
          <h5>Información del Operario</h5>
          <OperatorFormComponent
            operator={editedOperator}
            onSaveOperator={onOperatorSavedEventHandler}
            onCancel={onOperatorFormCancelHandler}
          />
        </div>
      )}
      {showUserForm && (
        <div className="modalForm">
          <h5>Información del Usuario</h5>
          <UsersFormComponent
            CurrentUser={editedUser}
            onSaveUser={onUserSavedEventHandler}
            onCancel={onUserFormCancelHandler}
          />
        </div>
      )}
      {showchangePasswordForm && (
        <div className="modalForm">
          <h5>Cambiar contraseña</h5>
          <UserChangePasswordFormComponent
            CurrentUser={editedUser!}
            onChangePassword={onUserChangedPassword}
            onCancel={onCancelUserChangedPassword}
          />
        </div>
      )}
    </>
  );
};
