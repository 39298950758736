import './UserComponent.css';
import { FaUser } from 'react-icons/fa';

// Stores
import * as AuthStore from '../../stores/authentication';
import { ApplicationState } from '../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const UserComponent = () => {
  const AuthState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  const dispatch = useDispatch();
  const history = useHistory();

  // events
  const onCloseSessionHandler = () => {
    dispatch(mapDispatchToProps.logout());
  };

  const onLoginHandler = () => {};

  useEffect(() => {
    if (AuthState?.isAuthenticate && !AuthState.isLoading) {
      const returnUrl = window.location.toString();
      //history.push('login/?returnUrl=' + returnUrl);
    }
  }, [AuthState?.isAuthenticate]);

  const UserAuthenticated = (
    <div className="UserContent">
      <div className="UserIcon">
        <FaUser />
      </div>
      <label>
        {AuthState?.authenticatedUser?.lastName},{' '}
        {AuthState?.authenticatedUser?.firstName}
      </label>
      <button className="btn btn-primary" onClick={onCloseSessionHandler}>
        Cerrar Sesión
      </button>
    </div>
  );

  const UserUnAuthenticated = (
    <div className="UserContent">
      {/* <button className="btn btn-info">Iniciar sesión</button> */}
    </div>
  );

  return AuthState?.isAuthenticate ? UserAuthenticated : UserUnAuthenticated;
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.authentication
});

const mapDispatchToProps = {
  ...AuthStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserComponent as any);
