import { MateriaPrima } from '../../../model/materia_prima';

export interface IProps {
  supply: MateriaPrima;
}

export const MateriaPrimaHeader = (props: IProps) => {
  return (
    <div className="EntityHeader">
      <h1>{props.supply.codigo}</h1>
      <h2>{props.supply.nombre}</h2>
      <p>{props.supply.descripcion}</p>
    </div>
  );
};

export default MateriaPrimaHeader;
