import { useCallback, useEffect, useState } from 'react';
import { OrderVerification } from '../../../model/finalizarOrden';
import { Operator } from '../../../model/operator';
import { OperatorSelectorComponent } from '../OperatorSelector/OperatorSelectorComponent';

//styles
import './OrdenVerificationFormComponent.css';

export interface IProps {
  OnFormDataChange?: (VerificationResult: OrderVerification) => void;
  isSubmitted: boolean;
  OnErrorsDetected?: (Errors: string[]) => void;
  OnChangeLitresManufactured?: (ActualLitresManufactured: number) => void;
}

export const OrderVerificationFormComponent = (props: IProps) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>();
  const [approved, setApproved] = useState<boolean>();
  const [gloss, setGloss] = useState<number>();
  const [density, setDensity] = useState<number>();
  const [litresManufactured, setLitresManufactured] = useState<number>();
  const [operarioId, setOperarioId] = useState<number>();

  //form field handlers
  const ApprovedFomFieldClickedHandler = () => {
    setApproved(true);
    console.log('aprobado');
  };
  const DisapprovedFomFieldClickedHandler = () => {
    setApproved(false);
    console.log('desaprobado');
  };
  const GlossFomFieldChangeHandler = (event: any) => {
    setGloss(Number(event.target.value));
  };
  const DensityFomFieldChangeHandler = (event: any) => {
    setDensity(Number(event.target.value));
  };
  const LitresManufacturedFomFieldChangeHandler = (event: any) => {
    setLitresManufactured(Number(event.target.value));
    if (props.OnChangeLitresManufactured)
      props.OnChangeLitresManufactured(Number(event.target.value) || 0);
  };

  const OpetarioFieldChangeHandler = (operator: Operator) => {
    setOperarioId(operator.id);
  };

  const ValidateFormData = () => {
    let ValidationResult: string[] = [];
    if (approved === undefined) {
      ValidationResult = [
        ...ValidationResult,
        'Debe especificar si el producto fabricado esta o no aprobado'
      ];
    }
    if (!gloss) {
      ValidationResult = [...ValidationResult, 'Debe especificar el gloss'];
    }
    if (!density) {
      ValidationResult = [...ValidationResult, 'Debe especificar la densidad'];
    }
    if (!litresManufactured) {
      ValidationResult = [
        ...ValidationResult,
        'Debe especificar los litros fabricados'
      ];
    }

    if (!operarioId) {
      ValidationResult = [
        ...ValidationResult,
        'Debe especificar un operario responsable'
      ];
    }

    console.log(ValidationResult);

    if (props.OnErrorsDetected) props.OnErrorsDetected(ValidationResult);
  };

  useEffect(() => {
    setFormSubmitted(props.isSubmitted);
    if (props.isSubmitted) ValidateFormData();
  }, [props.isSubmitted]);

  useEffect(() => {
    //if (props.isSubmitted) {
      console.log('ejecuta validacion de form');
      ValidateFormData();
    //}
    console.log('ejecuta cambio de datos form');
    if (props.OnFormDataChange)
      props.OnFormDataChange({
        aprobado: approved!,
        resultado_brillo: gloss!,
        resultado_densidad: density!,
        litros_fabricados: litresManufactured!,
        operarioId: operarioId!,
        comentariosFinales: ''
      });
  }, [approved, gloss, density, litresManufactured, operarioId]);

  return (
    <form className="formOrderVerification">
      <div className="formRadiobutton">
        <label className="form-label">
          Resultado verificación <span className="text-danger">*</span>
        </label>
        <div
          className="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradioResult"
            id="btnradioApprove"
            autoComplete="off"
            onClick={ApprovedFomFieldClickedHandler}
          />
          <label className="btn btn-outline-primary" htmlFor="btnradioApprove">
            Aprobado
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradioResult"
            id="btnradioDisapprove"
            autoComplete="off"
            onClick={DisapprovedFomFieldClickedHandler}
          />
          <label
            className="btn btn-outline-secondary"
            htmlFor="btnradioDisapprove"
          >
            Desaprobado
          </label>
        </div>
        {formSubmitted && approved === undefined && (
          <small className="text-danger">Dato requerido</small>
        )}
      </div>
      <div className="NombreProducto">
        <label className="form-label">
          Gloss <span className="text-danger">*</span>
        </label>
        <input
          type="number"
          title="Gloss"
          min="0.01"
          step=".01"
          className={
            formSubmitted && gloss === undefined
              ? 'form-control textFieldError'
              : 'form-control'
          }
          onChange={GlossFomFieldChangeHandler}
        />
        {formSubmitted && gloss === undefined && (
          <small className="text-danger">Dato requerido</small>
        )}
      </div>
      <div className="">
        <label className="form-label">
          Densidad <span className="text-danger">*</span>
        </label>
        <input
          type="number"
          title="Densidad"
          min="0.01"
          step=".01"
          className={
            formSubmitted && density === undefined
              ? 'form-control textFieldError'
              : 'form-control'
          }
          onChange={DensityFomFieldChangeHandler}
        />
        {formSubmitted && density === undefined && (
          <small className="text-danger">Dato requerido</small>
        )}
      </div>
      <div className="">
        <label className="form-label">
          Litros fabricados <span className="text-danger">*</span>
        </label>
        <input
          type="number"
          title="Litros fabricados"
          min="0.01"
          step=".01"
          className={
            formSubmitted && litresManufactured === undefined
              ? 'form-control textFieldError'
              : 'form-control'
          }
          onChange={LitresManufacturedFomFieldChangeHandler}
        />
        {formSubmitted && density === undefined && (
          <small className="text-danger">Dato requerido</small>
        )}
      </div>
      <div className="">
        <label className="form-label">
          Operario <span className="text-danger">*</span>
        </label>
        <OperatorSelectorComponent
          InitialSelection={operarioId || null}
          onOperatorSelectedEvent={OpetarioFieldChangeHandler}
        />
        {formSubmitted && operarioId === undefined && (
          <small className="text-danger">Dato requerido</small>
        )}
      </div>
    </form>
  );
};
