import { MeasurementUnit } from '../../model/MeasurementUnit';
import { Stock } from '../../model/Stock';
import { CommonState } from '../../stores/common/commonState';

export interface StockState extends CommonState {
  isLoadingMeasurementUnits: boolean;
  isLoadingMeasurementUnitsSuccess: boolean | undefined;
  FailGettingMeasurementUnits: boolean;
  isAddingPresentation: boolean;
  isAddingPresentationSuccess: boolean | undefined;
  FailAddingPresentation: boolean;
  isSavingPresentation: boolean;
  isSavingPresentationSuccess: boolean | undefined;
  FailSavingPresentation: boolean;
  MeasurementUnits: MeasurementUnit[];
  isLoadingStock: boolean;
  isLoadingStockSuccess: boolean | undefined;
  FailGetStock: boolean;
  CurrentStock: Stock | undefined;
  isAddingNewStockTransaction: boolean;
  isAddedStockTransactionSuccess: boolean | undefined;
  FailAddStockTransaction: boolean;
}

export const unloadedState: StockState = {
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  isLoadingMeasurementUnits: false,
  isLoadingMeasurementUnitsSuccess: undefined,
  isSaving: false,
  isSavingSuccess: undefined,
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,
  FailSaving: false,
  FailGettingAll: false,
  FailGettingOne: false,
  FailGettingMeasurementUnits: false,
  isFiltered: false,
  filterCriteria: undefined,
  MeasurementUnits: [],
  Error: undefined,
  isAddingPresentation: false,
  isAddingPresentationSuccess: undefined,
  FailAddingPresentation: false,
  isSavingPresentation: false,
  isSavingPresentationSuccess: undefined,
  FailSavingPresentation: false,
  CurrentStock: undefined,
  isLoadingStock: false,
  isLoadingStockSuccess: undefined,
  FailGetStock: false,
  isAddingNewStockTransaction: false,
  isAddedStockTransactionSuccess: undefined,
  FailAddStockTransaction: false,
  isDeletingOne: false,
  isDeletingOneSuccess: undefined,
  FailDeletingOne: false
};
