import { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { ActionMeta, SingleValue } from 'react-select';
import Select from 'react-select';
import { MeasurementUnitType } from '../../../../model/enums/MeasurementUnitsEnum';
import { MeasurementUnit } from '../../../../model/MeasurementUnit';
import { ApplicationState } from '../../../../stores';

// Stores
import * as StockStore from '../../../../stores/Stock';

export interface IProps {
  category: MeasurementUnitType;
  InitialSelection: number | null;
  onMeasurementUnitSelectedEvent?: (unit: MeasurementUnit) => void;
}

export const MeasurementUnitSelectorComponent = (props: IProps) => {
  const dispatch = useDispatch();

  const defaultOption: MeasurementUnit = {
    id: -1,
    codigo: 'Seleccione',
    descripcion: '',
    liquido: false,
    peso: false,
    unidad: false
  };

  useEffect(() => {
    dispatch(mapDispatchToProps.GetMeasurementUnit(props.category));
  }, []);

  useEffect(() => {
    if (props.InitialSelection !== null)
      setMeasureUnitSelected(
        CurrentStockState?.MeasurementUnits.find(
          (unit: MeasurementUnit) => unit.id === props.InitialSelection
        ) || null
      );
  }, [props.InitialSelection]);

  const CurrentStockState = useSelector(
    (state: ApplicationState) => state.Stock
  );

  const [MeasureUnitSelected, setMeasureUnitSelected] =
    useState<MeasurementUnit | null>(null);

  const changeSelectionHandler = (
    newValue: SingleValue<MeasurementUnit>,
    actionMeta: ActionMeta<MeasurementUnit>
  ) => {
    setMeasureUnitSelected(newValue);
    if (props.onMeasurementUnitSelectedEvent && newValue)
      props.onMeasurementUnitSelectedEvent(newValue);
  };

  return (
    <Select
      options={CurrentStockState?.MeasurementUnits.filter(
        (unit: MeasurementUnit) =>
          props.category === MeasurementUnitType.all
            ? true
            : props.category === MeasurementUnitType.onlyLiquid && unit.liquido
            ? true
            : props.category === MeasurementUnitType.onlyWeight && unit.peso
            ? true
            : props.category === MeasurementUnitType.allWeight &&
              (unit.peso || unit.liquido)
            ? true
            : props.category === MeasurementUnitType.onlyUnit && unit.unidad
            ? true
            : props.category === MeasurementUnitType.notGeneric &&
              (unit.liquido || unit.peso || unit.unidad)
            ? true
            : false
      )}
      value={MeasureUnitSelected}
      placeholder="Seleccione..."
      getOptionLabel={(unit: MeasurementUnit) =>
        unit.codigo + ' (' + unit.descripcion + ')'
      }
      getOptionValue={(unit: MeasurementUnit) => unit.id.toString()}
      onChange={changeSelectionHandler}
      defaultValue={defaultOption}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Stock
});

const mapDispatchToProps = {
  ...StockStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasurementUnitSelectorComponent as any);
