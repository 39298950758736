import { connect } from 'react-redux';
import { MateriaPrima } from '../../../model/materia_prima';
import { ApplicationState } from '../../../stores';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

//store
import * as SuppliesStore from '../../../stores/Materia_Prima';

interface ConnectedProps {
  isLoading: boolean;
  loadingSuccess: boolean | undefined;
  failOnLoading: boolean;
  error: TypeError | undefined;
  LowStockSupplies: MateriaPrima[];
}

interface DispatchProps {
  GetLowStockSupplies: () => void;
}

export type Props = ConnectedProps & DispatchProps;

export const LowStockWidgetComponent = ({
  isLoading,
  loadingSuccess,
  failOnLoading,
  error,
  LowStockSupplies,
  GetLowStockSupplies,
  ...props
}: Props) => {
  useEffect(() => {
    GetLowStockSupplies();
  }, [GetLowStockSupplies]);

  const status = isLoading ? (
    <>Cargando...</>
  ) : failOnLoading ? (
    <>Error al cargar el stock de materias primas</>
  ) : (
    <></>
  );

  return !loadingSuccess ? (
    status
  ) : (
    <div className="seccion OrdenesWidgetCard">
      <label className="SubContentTitle">Materias primas con stock bajo</label>
      <hr />
      <div style={{ maxHeight: 'calc(100vh - 25em)', overflow: 'auto' }}>
        <table className="table table-hover">
          <thead>
            <th>Materia prima</th>
            <th style={{ width: 130, textAlign: 'center' }}>Stock Actual</th>
            <th style={{ width: 130, textAlign: 'center' }}>Mínimo</th>
          </thead>
          <tbody>
            {LowStockSupplies.map((item: MateriaPrima) => (
              <tr>
                <td className="text-uppercase">
                  <NavLink to={'./supplies/' + item.id}>
                    {' '}
                    {item.nombre} | {item.codigo}
                  </NavLink>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    paddingRight: 25,
                    fontWeight: 'bold',
                    color: 'red'
                  }}
                >
                  <NumberFormat
                    value={item.stock.cantidad}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />{' '}
                  {item.unidadDeMedida.codigo}
                </td>
                <td style={{ textAlign: 'right', paddingRight: 25 }}>
                  <NumberFormat
                    value={item.minimoStockAceptable}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />{' '}
                  {item.unidadDeMedida.codigo}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <label>Materias primas con stock por debajo de mínimo</label>
        <label
          className="badge bg-danger"
          style={{ fontSize: 19, marginLeft: 10, maxWidth: '3em' }}
        >
          {LowStockSupplies.length}
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.Supplies?.isLoadingLowStockSupplies,
  loadingSuccess: state.Supplies?.loadingLowStockSuppliesSuccess,
  failOnLoading: state.Supplies?.failOnLoadingLowStockSupplies,
  error: state.Supplies?.Error,
  LowStockSupplies: state.Supplies?.lowStockSupplies
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetLowStockSupplies: SuppliesStore.actionCreators.GetLowStockSupplies
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LowStockWidgetComponent as any);
