import * as React from 'react';
import { useEffect } from 'react';
import '../../custom.css';
import { useForm } from 'react-hook-form';

import { Producto } from '../../model/producto';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as ProductosStore from '../../stores/Productos';
interface IProps {
  ProductSavedEvent: (newProducto: Producto) => void;
  CancelEvent: () => void;
  producto: Producto | undefined;
}

export const ProductoForm = (props: IProps) => {
  const dispatch = useDispatch();
  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos
  );

  useEffect(() => {
    if (props.producto) {
      setValue('id', props.producto?.id);
      setValue('codigo', props.producto?.codigo);
      setValue('nombre', props.producto?.nombre);
      setValue('descripcion', props.producto?.descripcion);
      setValue('tiempoProduccion', props.producto?.tiempoProduccion);
      setValue('tiempoEnvasado20L', props.producto?.tiempoEnvasado20L);
      setValue('tiempoEnvasado10L', props.producto?.tiempoEnvasado10L);
      setValue('tiempoEnvasado4L', props.producto?.tiempoEnvasado4L);
      setValue('tiempoEnvasadoMenor4L', props.producto?.tiempoEnvasadoMenor4L);
    }
  }, []);

  useEffect(() => {
    if (ProductosState?.producto) {
      props.ProductSavedEvent(ProductosState?.producto!);
    }
  }, [ProductosState?.producto?.id]);

  const CancelFormHandler = () => {
    reset();
    props.CancelEvent();
  };

  const defaultValues = {
    codigo: '',
    nombre: '',
    descripcion: '',
    id: -1,
    tiempoProduccion: 0,
    tiempoEnvasado20L: 0,
    tiempoEnvasado10L: 0,
    tiempoEnvasado4L: 0,
    tiempoEnvasadoMenor4L: 0,
  };

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<Producto>({
    defaultValues: defaultValues
  });

  const onSubmit = (data: Producto) => {
    dispatch(mapDispatchToProps.saveProducto(data));
  };

  return (
    <>
      <div className="contentpage">
        <div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="formContent">
              {!ProductosState?.StatusOk && (
                <div className="ErrorContainer">
                  <ul>
                    {ProductosState &&
                      ProductosState.Error &&
                      ProductosState.Error.Errors.map((error: string) => (
                        <li>{error}</li>
                      ))}
                  </ul>
                </div>
              )}
              <label className="form-label">
                Código de producto <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código de producto"
                className="form-control upper"
                {...register('codigo', {
                  required: true,
                  maxLength: 20,
                  minLength: 3
                })}
                maxLength={20}
                onKeyDown={(e) => {
                  switch(e.key) { 
                  case ' ':
                    e.preventDefault();
                    return false;
                  default:
                    return true;
                }}}
                onPaste={(e) => {
                  e.preventDefault();
                  let paste = (e.clipboardData).getData('text');
                  const noSpaces = paste.replace(/\s/g, '');    
                  paste = noSpaces;
                  document.execCommand("insertHTML", false, paste);
                }}
              />

              <small className="text-danger">
                {errors.codigo?.type === 'required' && 'El código es requerido'}
                {errors.codigo?.type === 'minLength' &&
                  'El código es muy chico'}
                {errors.codigo?.type === 'maxLength' &&
                  'El código es muy grande'}
              </small>

              <label className="form-label">
                Descripción del producto <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Descripción"
                className="form-control"
                {...register('nombre', {
                  required: true,
                  maxLength: 250,
                  minLength: 3
                })}
                maxLength={250}
              />

              <small className="text-danger">
                {errors.nombre?.type === 'required' && 'El nombre es requerido'}
                {errors.nombre?.type === 'minLength' &&
                  'El nombre es muy chico'}
                {errors.nombre?.type === 'maxLength' &&
                  'El nombre es muy grande'}
              </small>

              <label className="form-label">Comentarios</label>
              <input
                type="text"
                className="form-control"
                title="Comentarios"
                {...register('descripcion', {
                  required: false,
                  maxLength: 250,
                  minLength: 3
                })}
                maxLength={140}
              />

              <small className="text-danger">
                {errors.descripcion?.type === 'minLength' &&
                  'La descripción es muy chica'}
                {errors.descripcion?.type === 'maxLength' &&
                  'La descripción es muy grande'}
              </small>

              <label className="form-label">
                Tiempo de Producción
              </label>
              <input
                type="number"
                title="Tiempo de Producción"
                className="form-control"
                maxLength={250}
                {...register('tiempoProduccion', {
                })}
              />

              <label className="form-label">
                Tiempo de envasado 20L
              </label>
              <input
                type="number"
                title="Tiempo de Producción"
                className="form-control"
                maxLength={250}
                {...register('tiempoEnvasado20L', {
                })}
              />

              <label className="form-label">
                Tiempo de envasado 10L
              </label>
              <input
                type="number"
                title="Tiempo de Producción 10L"
                className="form-control"
                maxLength={250}
                {...register('tiempoEnvasado10L', {
                })}
              />

              <label className="form-label">
                Tiempo de envasado 4L
              </label>
              <input
                type="number"
                title="Tiempo de Producción 4L"
                className="form-control"
                maxLength={250}
                {...register('tiempoEnvasado4L', {
                })}
              />

              <label className="form-label">
                Tiempo de envasado &lt; 4L
              </label>
              <input
                type="number"
                title="Tiempo de Producción < 4L"
                className="form-control"
                maxLength={250}
                {...register('tiempoEnvasadoMenor4L', {
                })}
              />
            </div>

            <div className="formButtons">
              <button type="submit" className="btn btn-primary">
                Grabar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={CancelFormHandler}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos
});

const mapDispatchToProps = {
  ...ProductosStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductoForm as any);
