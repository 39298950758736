import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { ListaFormula } from '../../model/lista_formula';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as FormulaStore from '../../stores/Formula';
import { PasosTable } from './pasosTable';
import { ItemPasos } from './itemPasos';
import { Pasos } from '../../model/pasos';
import { MateriaPrima } from '../../model/materia_prima';

//styles
import '../../custom.css';
import './formula.css';

export interface IProps {
  FormulaId: number;
}

export const PasosForm = (props: IProps) => {
  const dispatch = useDispatch();
  const [orden, setOrden] = React.useState(1);
  const FormulaState = useSelector((state: ApplicationState) => state.formulas);
  const pasos = useSelector((state: ApplicationState) => state.formulas?.pasos);
  const [showForm, setshowForm] = useState<boolean>(false);

  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  useEffect(() => {
    if (FormulaState) {
      reset(FormulaState.formula);
    }
    if (pasos) {
      setOrden(pasos.length);
    }
  }, [FormulaState]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<ListaFormula>({});
  const onSubmit = (data: any) => {};
  const addPaso = (paso: Pasos) => {
    SuppliesState?.RawMaterials?.map((materia: MateriaPrima) => {
      if (paso.materiaprima && paso.materiaprima.id === materia.id) {
        console.log(materia);
        paso.materiaprima = materia;
      }
    });
    paso.orden = orden + 1;
    dispatch(mapDispatchToProps.addPasosFormula(paso));
  };
  const eliminarPaso = (value: Pasos) => {
    console.log(value);
    dispatch(mapDispatchToProps.deletePasosFormula(value, props.FormulaId));
  };
  const editPaso = (value: Pasos) => {
    dispatch({ type: 'SET_PASO', paso: value });
  };
  const cancelPaso = () => {
    setshowForm(false);
  };

  return (
    <React.Fragment>
      <div className="col-md-12 contentpage">
        <div className="col-md-12">
          <form key={1} onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12 mb-2">
              <Form.Label>Producto</Form.Label>
              <input
                title="hola"
                type="text"
                readOnly
                className="form-control"
                {...register('producto.nombre', {
                  required: true,
                  maxLength: 250,
                  minLength: 3
                })}
              />
            </div>
            <div className="col-md-12 mb-2">
              <Form.Label>Observaciones</Form.Label>
              <textarea
                aria-multiline={true}
                readOnly
                placeholder="Observacion"
                className="form-control"
                {...register('observacion', {
                  required: true,
                  maxLength: 250,
                  minLength: 3
                })}
              />
              {/* {errors.observacion?.type === "required" &&
                  "La descripcion es requerida"} */}
              {errors.observacion?.type === 'minLength' &&
                'La descripcion es muy chica'}
              {errors.observacion?.type === 'maxLength' &&
                'La descripcion es muy grande'}
            </div>
          </form>
        </div>

        {showForm && (
          <>
            <div className="PasosForm">
              <ItemPasos
                FormulaId={props.FormulaId}
                AddEvent={addPaso}
                CancelEvent={cancelPaso}
                isProducto={true}
              ></ItemPasos>
            </div>
          </>
        )}
        <PasosTable
          dataState={pasos}
          eliminarPaso={eliminarPaso}
          editPaso={editPaso}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(PasosForm as any);
