import { AppThunkAction } from '../../stores';

// services
import * as Services from '../../services/OrderServices';


// actions
import * as Actions from '../Actions/OrderActions';
import { ErrorType } from '../../model/FetchError';


type KnownAction =
    | Actions.Order_Cancel_Request_Action
    | Actions.Order_Cancel_Success_Action
    | Actions.Order_Cancel_Fail_Action;


export const actionCreators = {
    CancelOrder:
        (orderId: number): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                var state = getState();

                var token = state.authentication?.authenticatedUser?.jwToken as string;

                dispatch({
                    type: 'ORDER_CANCEL_REQUEST',
                    orderId: orderId
                });

                Services.CancelOrder(orderId, token)
                    .then(() => {
                        dispatch({
                            type: 'ORDER_CANCEL_SUCCESS',
                            orderId: orderId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'ORDER_CANCEL_FAIL',
                            Error: error
                        })
                    );

            }
};
