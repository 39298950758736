import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';

// stores
import * as StockStore from '../../../stores/Stock';

export interface IProps {
  EntityId: number;
  StockId: number;
  CurrentStockAmount: number;
  onSaveNewStockTransaction?: (entityId: number, newAmount: number, comments: string) => void;
  onCancel?: () => void;
}

export const ChangeStockAmountForm = (props: IProps) => {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState<boolean>(false);

  // form fields
  const [id, setId] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [comments, setComments] = useState<string>();
  const [formValidationError, setFormValidationError] = useState<string[]>([]);

  // stock state
  const StockState = useSelector((state: ApplicationState) => state.Stock);

  useEffect(() => {
    if (props.StockId) {
      setId(props.StockId);
      setAmount(props.CurrentStockAmount);
    }
  }, []);

  useEffect(() => {
    if (
      submitted &&
      StockState &&
      StockState.isAddedStockTransactionSuccess &&
      amount
    ) {
      if (props.onSaveNewStockTransaction)
        props.onSaveNewStockTransaction(props.EntityId, amount, comments!);
    }
  }, [StockState?.isAddedStockTransactionSuccess, submitted]);

  // form validation
  const FormValidation = (): boolean => {
    let validationResult: boolean = true;
    let Errors: string[] = [];

    if (!amount) {
      Errors = [...Errors, 'Debe informar la nueva cantidad de stock'];
      validationResult = false;
    }

    if (amount && amount < 0) {
      Errors = [...Errors, 'La cantidad de stock debe ser mayor o igual a 0'];
      validationResult = false;
    }
    
    if (comments === undefined || comments.length < 4) {
      Errors = [...Errors, 'debe ingresar el motivo del ajuste de stock'];
      validationResult = false
    }

    setFormValidationError(Errors);

    return validationResult;
  };

  // events
  const onCancelFormHandler = () => {
    if (props.onCancel) props.onCancel();
  };

  const onSubmitHandler = () => {
    setSubmitted(true);
    if (FormValidation()) {
      dispatch(
        mapDispatchToProps.AddNewStockTransaction({
          cantidadMovimiento: amount! - props.CurrentStockAmount,
          stockid: props.StockId,
          movimientoid: 4,
          observacion: comments!
        })
      );
    }
  };

  return (
    <>
      <div className="contentpage">
        <div>
          <form className="form">
            <div className="formContent">
              {StockState?.FailAddStockTransaction && StockState?.Error && (
                <div className="ErrorContainer">
                  <label>{StockState?.Error?.ErrorMessage}</label>
                  {StockState?.Error?.Errors && (
                    <ul>
                      {StockState?.Error?.Errors.map((error: string) => (
                        <li>{error}</li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {formValidationError.length > 0 && (
                <div className="ErrorContainer">
                  <label>Atención</label>
                  <ul>
                    {formValidationError.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <label className="form-label">
                Nueva cantidad de stock <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Nueva cantidad de stock"
                className="form-control"
                value={amount}
                onChange={(e: any) => setAmount(e.target.value)}
                maxLength={100}
              />
              <label className="form-label">
                Motivo <span className="text-danger">*</span>
              </label>
              <textarea
                title="Motivo"
                className="form-control"
                value={comments}
                onChange={(e: any) => setComments(e.target.value)}
                maxLength={500}
              />
              {submitted && !amount && (
                <small className="text-danger">Dato requerido</small>
              )}
            </div>
            <div className="formButtons">
              {StockState && !StockState.isAddingNewStockTransaction ? (
                <>
                  <button
                    type="button"
                    onClick={onSubmitHandler}
                    className="btn btn-primary"
                  >
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onCancelFormHandler}
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Stock
});

const mapDispatchToProps = {
  ...StockStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeStockAmountForm as any);
