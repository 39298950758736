import { UserComponent } from '../User/UserComponent';
import './HeaderComponent.css';

export const HeaderComponent = () => {
  return (
    <div className="HeaderContent">
      <UserComponent />
    </div>
  );
};
