import EmptyIcon from '../../../assets/empty-white-box.png';

//styles
import './WhiteSpaceComponent.css';

export interface IProps {
  title: string;
}

export const WhiteSpaceComponent = (props: IProps) => {
  return (
    <div className="WhiteSpaceContent">
      <img src={EmptyIcon} alt="No Content"></img>
      <label>{props.title}</label>
    </div>
  );
};
