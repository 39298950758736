import { useState } from "react";
import { useHistory } from 'react-router-dom';
import RemitoListComponent from "../components/Remito/List/RemitoList.Component";
import RemitoFormComponent from "../components/Remito/Form/RemitoForm.Component";

export const RemitosPage = () => {
    
const [ShowForm, setShowForm] = useState<boolean>(false);
  
  let history = useHistory();

  return (
    <>
      <div className="pageHeader">
        <h2>Remitos</h2>
        <button
          className="btn btn-primary"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Nuevo remito
        </button>
      </div>
        <RemitoListComponent />
      <>
        {ShowForm && (
          <div className="modalForm">
            <h5>Información del remito</h5>
            <RemitoFormComponent RemitoId={undefined} 
            MateriaPrimaId={undefined}
            onSaveRemito={() => {
              setShowForm(false);
            }}
            onCancel={() => {
              setShowForm(false);
            }}             
            />
          </div>
        )}
      </>
    </>
  );
      
}