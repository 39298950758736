import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Operator } from '../../../model/operator';
import { ApplicationState } from '../../../stores';

//stores

import * as OperatorStore from '../../../stores/Operators';

export interface IProps {
  onOperatorEditEvent?: (operator: Operator) => void;
}

export const OperatorListComponent = (props: IProps) => {
  const OperatorState = useSelector(
    (state: ApplicationState) => state.Operators
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mapDispatchToProps.GetOperators());
  }, [dispatch]);

  const onEditOperatorClickHandler = (operator: Operator) => {
    if (props.onOperatorEditEvent) props.onOperatorEditEvent(operator);
  };

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>Apellido</th>
            <th>Nombre</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {OperatorState?.Operators.map((operator: Operator) => (
            <tr>
              <td>
                <strong>{operator.id}</strong>
              </td>
              <td>{operator.apellido}</td>
              <td>{operator.nombre}</td>
              <td>
                <div className="controls">
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => onEditOperatorClickHandler(operator)}
                  >
                    Editar
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {OperatorState &&
        OperatorState.isLoadingAllSuccess &&
        OperatorState.Operators.length === 0 && (
          <label>No hay operadores registrados</label>
        )}
      {OperatorState && OperatorState.FailGettingAll && (
        <div className="ErrorContainer">
          {OperatorState.Error ? (
            <>
              <label>{OperatorState.Error?.ErrorMessage}</label>
              {OperatorState.Error.Errors && (
                <ul>
                  {OperatorState.Error?.Errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <label>Ocurrio un error al generar la solicitud</label>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Operators
});

const mapDispatchToProps = {
  ...OperatorStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorListComponent as any);
