import { ApplicationState } from '../../../stores';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { ProductCost } from '../../../model/productCost';
import { bindActionCreators } from 'redux';
import { ErrorType } from '../../../model/FetchError';

//store
import * as ProductStore from '../../../stores/Productos';

//style
import './ProductCostComponent.css';
import NumberFormat from 'react-number-format';

//Utils
import { formatCurrency } from '../../../utils/formatters';

export interface IProps {
  productId: number;
}

interface ConnectedProps {
  isLoading: boolean;
  successLoading: boolean | undefined;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  cost: ProductCost;
}

interface DispatchProps {
  GetProductCost: (productId: number) => void;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const ProductCostComponent = ({
  isLoading,
  successLoading,
  failOnLoading,
  error,
  cost,
  productId,
  GetProductCost,
  ...props
}: Props) => {
  useEffect(() => {
    GetProductCost(productId);
  }, [GetProductCost, productId]);

  const status = isLoading ? (
    <>Cargando...</>
  ) : failOnLoading ? (
    <div className="seccion productCost">
      <label>Costo por litro</label>
      <h6>{error?.ErrorMessage}</h6>
      <hr />
      <small className="text-info">
        Costo determinado en base a la última orden de fabricación
      </small>
    </div>
  ) : (
    <>Dio error</>
  );

  return !successLoading ? (
    status
  ) : (
    <div className='d-flex flex-column align-items-center'>
      <label className='text-center mb-2'>Costo de referencia</label>
      <div className="seccion productCost">
        <label>Costo por litro</label>
        <h4>
          {cost?.costoLitro && <><NumberFormat
            // value={Number.parseFloat(cost?.costoLitro?.toString())?.toFixed(3)}
            value={formatCurrency(cost?.costoLitro)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {' U$D/Ltr'}
          </>}
          {!cost?.costoLitro && 'No se pudeo determinado el costo'}
        </h4>
        <hr />
        <small className="text-info">
          Costo determinado en base a la última orden de fabricación
        </small>
      </div>
    </div>  
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
  isLoading: state.productos?.isGettingCost,
  successLoading: state.productos?.GettingCostSuccess,
  failOnLoading: state.productos?.failOnGettingCost,
  error: state.productos?.Error,
  cost: state.productos?.Cost
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetProductCost: ProductStore.actionCreators.GetProductCost
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCostComponent as any);
