import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { Orden } from '../../../../model/orden';

// styles
import './OrdenFabricacionEnCursoListComponent.css';
import moment from 'moment';

export interface IProps {
  order: Orden;
}

export const OrdenFabricacionEnCursoItem = (props: IProps) => {
  const history = useHistory();

  const ViewInfoHandler = () => {
    history.push('ordenes/info/' + props.order.id);
  };

  return (
    <div className="OrdersList" onClick={ViewInfoHandler}>
      <div>
        <small>Lote</small>
        <label>
          <strong>{props.order.lote}</strong>
        </label>
        <small>Fecha Solicitud</small>
        <label>
          <strong>
            {moment(props.order.fechaorden).add(-3, 'h').format('DD/MM/YYYY HH:mm')}
          </strong>
        </label>
      </div>
      <div>
        <small>producto</small>
        <strong>{props.order.producto?.codigo}</strong>
        <label>{props.order.producto?.nombre}</label>
      </div>
      <div>
        <small>Cantidad</small>
        <h3>{props.order.cantidad} Kg</h3>
      </div>
    </div>
  );
};
