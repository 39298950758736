import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { ErrorType } from '../../../model/FetchError';
import { StockTransaction } from '../../../model/Stock';
import { ApplicationState } from '../../../stores';
import { connect } from 'react-redux';

// Services
import * as StockServices from '../../../services/StockServices';

// Stores
import * as StockStore from '../../../stores/Stock';
import { MeasurementUnitType } from '../../../model/enums/MeasurementUnitsEnum';
import { FaArrowDown, FaArrowUp, FaEquals } from 'react-icons/fa';
import moment from 'moment';

export interface IProps {
  StockId?: number;
  refreshMovements?: boolean;
}

export const StockMovementListComponent = (props: IProps) => {
  const [movements, setMovements] = useState<StockTransaction[]>();
  const [error, setError] = useState<ErrorType>();

  const dispatch = useDispatch();

  useEffect(() => {
    if(props.StockId) {
      StockServices.GetStockMovements(props.StockId!)
      .then((movements: StockTransaction[]) => setMovements(movements))
      .catch((error: ErrorType) => setError(error));
      dispatch(mapDispatchToProps.GetMeasurementUnit(MeasurementUnitType.all));
    }
    
  }, [props.StockId !== undefined, props.refreshMovements]);

  const MovementsList = movements ? (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Movimiento</th>
          <th>Fecha</th>
          <th>Cantidad</th>
          <th>Stock resultante</th>
          <th>Observaciones</th>
        </tr>
      </thead>
      <tbody>
        {movements?.map((movement: StockTransaction) => (
          <tr>
            <td>{movement.movimiento.descripcion}</td>
            <td>
              {moment(movement.creado).add(-3, 'hours').format('DD/MM/YYYY HH:mm')}
            </td>
            <td>
              <div className="d-flex flex-row justify-content-between">
                <div>
                  {movement.cantidadMovimiento !== 0 ? (
                    movement.movimiento.signoMovimiento < 0 ? (
                      <FaArrowDown className="text-danger" />
                    ) : (
                      <FaArrowUp className="text-success" />
                    )
                  ) : (
                    <FaEquals className="text-info" />
                  )}
                </div>
                <div>{movement.cantidadMovimiento} Kg</div>
              </div>
            </td>
            <td className="pl-4">
              <span
                className={
                  movement.cantidadFinal >= 0 ? 'text-normal' : 'text-danger'
                }
              >
                {movement.cantidadFinal} Kg
              </span>
            </td>
            <td className="pl-4">
              <span className="text-normal">{movement.observacion}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <>No ha movimientos</>
  );

  const ErrorFetch = (
    <div className="ErrorContainer">
      {error && <label>{error!.ErrorMessage}</label>}
      {error?.Errors && (
        <ul>
          {error.Errors.map((error: string) => (
            <li>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );

  return error === undefined ? MovementsList : ErrorFetch;
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Stock
});

const mapDispatchToProps = {
  ...StockStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockMovementListComponent as any);
