import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as AuthenticationStore from '../../stores/authentication';
import { useHistory, useLocation } from 'react-router-dom';
import './login.css';
// import ExpoColorBackground from '../../assets/expocolorbackground.jpg';
import { HeaderBarComponent } from '../../components/HeaderBar/HeaderBarcomponent';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const LoginPage = () => {
  const [user, setUser] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  let query = useQuery();
  const returnUrl = query.get('returnUrl');
  const AuthenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  const handleUserInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const userName = event.target.value;
    setUser(userName);
  };

  const handlePassInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const userPass = event.target.value;
    setPass(userPass);
  };

  console.log(AuthenticationState);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (AuthenticationState?.isAuthenticate) {
      if (returnUrl) {
        window.location.replace(returnUrl);
      } else {
        history.push('/home');
      }
    } else {
      if (AuthenticationState?.failOnAuthenticate) {
        setErrorMessage(AuthenticationState.error?.ErrorMessage);
        setPass('');
      }
    }
  }, [
    AuthenticationState?.isAuthenticate,
    AuthenticationState?.failOnAuthenticate
  ]);

  const handleButtonClick = (e: any) => {
    e.preventDefault();
    if (user && pass) {
      dispatch(mapDispatchToProps.authenticate(user?.toLowerCase(), pass));
    } else {
      setErrorMessage('Verifique la información ingresada');
      setPass('');
    }
  };

  return (
    <>
      {/* <div className="loginBK">
        <img src={ExpoColorBackground} alt="" />
      </div> */}
      <div className="LoginForm">
        <HeaderBarComponent />
        <form onSubmit={handleButtonClick}>
          <h3>Inicio de Sesión</h3>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div className="mb-3">
            <label className="form-label">Usuario</label>
            <input
              type="text"
              required
              value={user}
              className="form-control"
              onChange={handleUserInputChange}
              id="userinput"
              placeholder="usuario"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contraseña</label>
            <input
              type="password"
              required
              value={pass}
              className="form-control"
              onChange={handlePassInputChange}
              id="passinput"
              placeholder="clave"
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary mb-2 float-right"
            onClick={handleButtonClick}
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.authentication
});

const mapDispatchToProps = {
  ...AuthenticationStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage as any);
