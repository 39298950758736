import { useEffect, useState } from 'react';
import { Operator } from '../../../model/operator';
import { ApplicationState } from '../../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';

// stores
import * as OperatorStore from '../../../stores/Operators';

export interface IProps {
  operator: Operator | undefined;
  onSaveOperator?: (operator: Operator) => void;
  onCancel?: () => void;
}

export const OperatorFormComponent = (props: IProps) => {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState<boolean>(false);

  // form fields
  const [id, setId] = useState<number>();
  const [name, setName] = useState<string>();
  const [surname, setSurname] = useState<string>();
  const [formValidationError, setFormValidationError] = useState<string[]>([]);

  // operator state
  const OperatorState = useSelector(
    (state: ApplicationState) => state.Operators
  );

  useEffect(() => {
    if (props.operator) {
      setId(props.operator.id);
      setName(props.operator.nombre);
      setSurname(props.operator.apellido);
    }
  }, []);

  useEffect(() => {
    if (
      submitted &&
      OperatorState &&
      (OperatorState.isAddingSuccess || OperatorState.isSavingSuccess) &&
      OperatorState.SelectedOperator
    ) {
      if (props.onSaveOperator)
        props.onSaveOperator(OperatorState.SelectedOperator);
    }
  }, [
    OperatorState?.isAddingSuccess,
    OperatorState?.isSavingSuccess,
    submitted
  ]);

  // form validation
  const FormValidation = (): boolean => {
    let validationResult: boolean = true;
    let Errors: string[] = [];

    if (!name) {
      Errors = [...Errors, 'Debe informar el nombre del operador'];
      validationResult = false;
    }

    if (!surname) {
      Errors = [...Errors, 'Debe informar el apellido del operador'];
      validationResult = false;
    }

    setFormValidationError(Errors);

    return validationResult;
  };

  // events
  const onCancelFormHandler = () => {
    if (props.onCancel) props.onCancel();
  };

  const onSubmitHandler = () => {
    setSubmitted(true);
    if (FormValidation()) {
      if (id)
        dispatch(
          mapDispatchToProps.SaveOperator({
            id: id,
            apellido: surname!,
            nombre: name!
          })
        );
      else
        dispatch(
          mapDispatchToProps.AddOperator({ apellido: surname!, nombre: name! })
        );
    }
  };

  return (
    <>
      <div className="contentpage">
        <div>
          <form className="form">
            <div className="formContent">
              {(OperatorState?.FailAdding || OperatorState?.FailSaving) &&
                OperatorState?.Error && (
                  <div className="ErrorContainer">
                    <label>{OperatorState?.Error?.ErrorMessage}</label>
                    <ul>
                      {OperatorState?.Error?.Errors.map((error: string) => (
                        <li>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
              {formValidationError.length > 0 && (
                <div className="ErrorContainer">
                  <label>Atención</label>
                  <ul>
                    {formValidationError.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <label className="form-label">
                Nombre <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código de materia prima"
                className="form-control"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                maxLength={100}
              />
              {submitted && !surname && (
                <small className="text-danger">Dato requerido</small>
              )}
              <label className="form-label">
                Apellido <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código de materia prima"
                className="form-control"
                maxLength={100}
                value={surname}
                onChange={(e: any) => setSurname(e.target.value)}
              />
              {submitted && !name && (
                <small className="text-danger">Dato requerido</small>
              )}
            </div>
            <div className="formButtons">
              {OperatorState &&
              !OperatorState.isAdding &&
              !OperatorState?.isSaving ? (
                <>
                  <button
                    type="button"
                    onClick={onSubmitHandler}
                    className="btn btn-primary"
                  >
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onCancelFormHandler}
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Operators
});

const mapDispatchToProps = {
  ...OperatorStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorFormComponent as any);
