import { AdditionalRawMaterial } from '../../../model/AdditionalRawMaterial';
import { AdditionalComponentForm } from './OrderAdditionalComponentForm';

//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';

export interface IProps {
  OrderId: number;
  AdditionalRawMaterials: AdditionalRawMaterial[];
  onAddAdditionalRawMaterial?: (newRawMaterial: AdditionalRawMaterial) => void;
  onDeleteAdditionalRawMaterial?: (
    DeletedRawMaterial: AdditionalRawMaterial
  ) => void;
}

export const OrderAdditionalComponentList = (props: IProps) => {
  const [additionalRawMaterialsList, setAdditionalRawMaterialList] = useState<
    AdditionalRawMaterial[]
  >(props.AdditionalRawMaterials || []);

  // just in case that props AdditionalRawMaterials be updated from an external process
  useEffect(() => {
    const ConcatenatedArray: AdditionalRawMaterial[] =
      props.AdditionalRawMaterials.concat(additionalRawMaterialsList);

    setAdditionalRawMaterialList(
      ConcatenatedArray.filter(
        (item: AdditionalRawMaterial, pos: number) =>
          ConcatenatedArray.indexOf(item) === pos
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AdditionalRawMaterials]);

  //add material handler
  const OnAddAdditionalRawMaterialHandler = (
    newAdditionalRawMaterial: AdditionalRawMaterial
  ) => {
    setAdditionalRawMaterialList([
      ...additionalRawMaterialsList,
      newAdditionalRawMaterial
    ]);
    if (props.onAddAdditionalRawMaterial)
      props.onAddAdditionalRawMaterial(newAdditionalRawMaterial);
  };

  const OnDeleteAdditionalRawMaterialHandler = (
    DeletedAdditionalRawMaterial: AdditionalRawMaterial
  ) => {
    setAdditionalRawMaterialList(
      additionalRawMaterialsList.filter((item: AdditionalRawMaterial) =>
        item.materiaprimaid === DeletedAdditionalRawMaterial.materiaprimaid
          ? false
          : true
      )
    );

    if (props.onDeleteAdditionalRawMaterial)
      props.onDeleteAdditionalRawMaterial(DeletedAdditionalRawMaterial);
  };

  return (
    <>
      <AdditionalComponentForm
        SaveOnSubmit={false}
        OrderId={props.OrderId}
        OnAddComponentEvent={OnAddAdditionalRawMaterialHandler}
        AllReadyAddedComponents={additionalRawMaterialsList}
      />
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Materia Prima</th>
            <th>Cantidad</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {additionalRawMaterialsList.map((item: AdditionalRawMaterial) => (
            <tr key={item.materiaprimaid}>
              <td>
                <h5>{item.materiaprima?.codigo}</h5>
                <label>{item.materiaprima?.nombre}</label>
              </td>
              <td>
                <h4>{item.cantidad}Kg.</h4>
              </td>
              <td>
                <button
                  title="Eliminar"
                  className="btn btn-light"
                  onClick={() => OnDeleteAdditionalRawMaterialHandler(item)}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {additionalRawMaterialsList.length === 0 && (
        <label>No hay informado materiales adicionales</label>
      )}
    </>
  );
};
