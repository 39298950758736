import { useEffect, useState } from 'react';
import '../../custom.css';
import { useForm } from 'react-hook-form';

import { MateriaPrima, NewMateriaPrima } from '../../model/materia_prima';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import { MeasurementUnitSelectorComponent } from '../stock/measurementUnits/selector/measurementUnitSelectorComponent';
import { MeasurementUnitType } from '../../model/enums/MeasurementUnitsEnum';
import { MeasurementUnit } from '../../model/MeasurementUnit';

// stores
import * as SuppliesStore from '../../stores/Materia_Prima';
import * as StockStore from '../../stores/Stock';

interface IProps {
  MateriaPrimaSaveEvent: (newMateriaPrima: MateriaPrima) => void;
  CancelEvent: () => void;
  supply: MateriaPrima | undefined;
}

export const MateriaPrimaForm = (props: IProps) => {
  const dispatch = useDispatch();
  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );
  const [EsEnvase, setEsEnvase] = useState<boolean>(
    props.supply?.esEnvase || false
  );

  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (props.supply) {
      setValue('id', props.supply.id);
      setValue('codigo', props.supply.codigo);
      setValue('nombre', props.supply.nombre);
      setValue('descripcion', props.supply.descripcion);
      setValue('codigoproveedor', props.supply.codigoproveedor);
      setValue('descripcionproveedor', props.supply.descripcionproveedor);
      setValue('esEnvase', props.supply.esEnvase);
      setValue('capacidad', props.supply?.capacidad);
      setValue('unidadDeMedidaId', props.supply?.unidadDeMedidaId);
      setValue(
        'unidadDeMedidaCapacidadId',
        props.supply?.unidadDeMedidaCapacidadId
      );
      setValue('precioReferencia', props.supply?.precioReferencia);
      setValue('minimoStockAceptable', props.supply?.minimoStockAceptable);
    }
  }, []);

  useEffect(() => {
    console.log('ejecuta salida otra');
    console.log('submitted', submitted);
    console.log('saving', SuppliesState?.isSavingSuccess);
    console.log('adding', SuppliesState?.isAddingSuccess);
    console.log('selected', SuppliesState?.SelectedSupply);
    if (
      submitted &&
      SuppliesState &&
      (SuppliesState.isSavingSuccess || SuppliesState.isAddingSuccess) &&
      SuppliesState.SelectedSupply
    ) {
      console.log('ejecuta salida');
      props.MateriaPrimaSaveEvent(SuppliesState?.SelectedSupply!);
    }
  }, [
    submitted,
    SuppliesState?.SelectedSupply?.id,
    SuppliesState?.isSavingSuccess,
    SuppliesState?.isAddingSuccess
  ]);

  useEffect(() => {
    setSubmitted(false);
    dispatch(mapDispatchToProps.GetMeasurementUnit(MeasurementUnitType.all));
  }, []);

  const CancelFormHandler = () => {
    reset();
    props.CancelEvent();
  };

  const esEnvaseChangeEventHandler = (e: any) => {
    setEsEnvase(e.target.checked);
  };

  const defaultValues = {
    id: -1,
    nombre: '',
    codigo: '',
    descripcion: '',
    codigoproveedor: '',
    descripcionproveedor: '',
    codigoproductoproveedor: ''
  };
  const {
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<MateriaPrima>({
    defaultValues: defaultValues
  });

  const onSubmit = (data: MateriaPrima) => {
    setSubmitted(true);
    dispatch(mapDispatchToProps.SaveSupply(data));
  };

  const onSubmitNew = (data: NewMateriaPrima) => {
    setSubmitted(true);
    dispatch(mapDispatchToProps.AddSupply(data));
  };

  return (
    <>
      <div className="contentpage">
        <div>
          <form
            className="form"
            onSubmit={handleSubmit(props.supply ? onSubmit : onSubmitNew)}
          >
            <div className="formContent">
              {(SuppliesState?.FailAdding || SuppliesState?.FailSaving) &&
                SuppliesState?.Error && (
                  <div className="ErrorContainer">
                    <label>{SuppliesState?.Error?.ErrorMessage}</label>
                    {SuppliesState?.Error.Errors && (
                      <ul>
                        {SuppliesState?.Error?.Errors.map((error: string) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              <label className="form-label">
                Código de materia prima <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código de materia prima"
                className="form-control  upper"
                {...register('codigo', {
                  required: true,
                  maxLength: 250,
                  minLength: 1
                })}
                maxLength={20}
                onKeyDown={(e) => {
                  switch(e.key) { 
                  case ' ':
                    e.preventDefault();
                    return false;
                  default:
                    return true;
                }}}
                onPaste={(e) => {
                  e.preventDefault();
                  let paste = (e.clipboardData).getData('text');
                  const noSpaces = paste.replace(/\s/g, '');     
                  paste = noSpaces;
                  document.execCommand("insertHTML", false, paste);
                }}
              />
              <small className="text-danger">
                {errors.codigo?.type === 'required' && 'El código es requerido'}
                {errors.codigo?.type === 'minLength' &&
                  'El codigo es muy chico'}
                {errors.codigo?.type === 'maxLength' &&
                  'El codigo es muy grande'}
              </small>

              <label className="form-label">
                Nombre de materia prima <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Nombre de materia prima"
                className="form-control"
                {...register('nombre', {
                  required: true,
                  maxLength: 250,
                  minLength: 3
                })}
                maxLength={250}
              />
              <small className="text-danger">
                {errors.nombre?.type === 'required' && 'El nombre es requerido'}
                {errors.nombre?.type === 'minLength' &&
                  'El nombre es muy chico'}
                {errors.nombre?.type === 'maxLength' &&
                  'El nombre es muy grande'}
              </small>

              <label className="form-label">Comentarios</label>
              <input
                type="text"
                title="Descripción"
                className="form-control"
                {...register('descripcion', {
                  required: false,
                  maxLength: 250,
                  minLength: 3
                })}
                maxLength={250}
              />
              <small className="text-danger">
                {errors.descripcion?.type === 'required' &&
                  'La descripción es requerida'}
                {errors.descripcion?.type === 'minLength' &&
                  'La descripción es muy chica'}
                {errors.descripcion?.type === 'maxLength' &&
                  'La descripción es muy grande'}
              </small>

              <label className="form-label">
                Código de proveedor <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código Proveedor"
                className="form-control upper"
                {...register('codigoproveedor', {
                  required: true,
                  maxLength: 20,
                  minLength: 1
                })}
                maxLength={20}
              />
              <small className="text-danger">
                {errors.codigoproveedor?.type === 'required' &&
                  'El código es requerido'}
                {errors.codigoproveedor?.type === 'minLength' &&
                  'El código es muy chico'}
                {errors.codigoproveedor?.type === 'maxLength' &&
                  'El código es muy grande'}
              </small>
              <label className="form-label">
                Proveedor <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Proveedor"
                className="form-control"
                maxLength={250}
                {...register('descripcionproveedor', {
                  required: true,
                  maxLength: 250,
                  minLength: 3
                })}
              />
              <small className="text-danger">
                {errors.descripcionproveedor?.type === 'required' &&
                  'El proveedor es requerida'}
              </small>
              <label className="form-label">
                Es Envase? <span className="text-danger">*</span>
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                title="Identifica esta materia prima como un envase"
                onClick={esEnvaseChangeEventHandler}
                {...register('esEnvase', {
                  required: false
                })}
              />
              {EsEnvase && (
                <label className="form-label">
                  Capacidad <span className="text-danger">*</span>
                </label>
              )}
              <div className="horizontal">
                {EsEnvase && (
                  <>
                    <input
                      type="number"
                      title="Capacidad"
                      className="form-control"
                      {...register('capacidad', {
                        required: EsEnvase ? true : false
                      })}
                    />
                    <MeasurementUnitSelectorComponent
                      category={
                        EsEnvase
                          ? MeasurementUnitType.allWeight
                          : MeasurementUnitType.notGeneric
                      }
                      InitialSelection={
                        props.supply?.unidadDeMedidaCapacidadId
                          ? props.supply?.unidadDeMedidaCapacidadId
                          : null
                      }
                      onMeasurementUnitSelectedEvent={(unit: MeasurementUnit) =>
                        setValue('unidadDeMedidaCapacidadId', unit.id)
                      }
                      {...register('unidadDeMedidaCapacidadId', {
                        required: EsEnvase ? true : false
                      })}
                    />
                  </>
                )}
              </div>
              {EsEnvase && (
                <small className="text-danger">
                  {errors.capacidad?.type === 'required' &&
                    'La capacidad es requerida'}
                </small>
              )}
              <small className="text-danger">
                {errors.unidadDeMedidaCapacidadId?.type === 'required' &&
                  'La unidad de medida es requerida'}
              </small>
              <label className="form-label">
                Unidad de medida<span className="text-danger">*</span>
              </label>
              <MeasurementUnitSelectorComponent
                category={MeasurementUnitType.notGeneric}
                InitialSelection={
                  props.supply?.unidadDeMedidaId
                    ? props.supply?.unidadDeMedidaId
                    : null
                }
                onMeasurementUnitSelectedEvent={(unit: MeasurementUnit) =>
                  setValue('unidadDeMedidaId', unit.id)
                }
                {...register('unidadDeMedidaId', {
                  required: true
                })}
              />
              <hr />
              <label className="form-label">
                Precio de Referencia <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                step={0.01}
                min={0.01}
                max={999999999.99}
                className="form-control"
                title="Precio de referencia de la materia prima"
                {...register('precioReferencia', {
                  required: true
                })}
              />
              <small className="text-danger">
                {errors.precioReferencia?.type === 'required' &&
                  'El precio de referencia es requerido'}
              </small>
              <hr />
              <label className="form-label">
                Mínimo stock aceptable <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                step={0.01}
                min={0.01}
                max={999999999.99}
                title="Mínimo Stock aceptable"
                className="form-control"
                maxLength={250}
                {...register('minimoStockAceptable', {
                  required: false
                })}
              />
              <small className="text-danger">
                {errors.minimoStockAceptable?.type === 'required' &&
                  'El mínimo stock aceptable es requerido'}
              </small>
            </div>
            <div className="formButtons">
              {!SuppliesState?.isAdding && !SuppliesState?.isSaving ? (
                <>
                  <button type="submit" className="btn btn-primary">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={CancelFormHandler}
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Supplies,
  ...state.Stock
});

const mapDispatchToProps = {
  ...SuppliesStore.actionCreators,
  ...StockStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MateriaPrimaForm as any);
