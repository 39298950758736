import { ErrorType } from '../../../model/FetchError';
import { Orden } from '../../../model/orden';

//stores 
import * as OrderStore from '../../../redux/Middleware/OrderMiddleware'
import { ApplicationState } from '../../../stores';

//#region Props

interface IProps {
    orderId: number;
    isCanceled: boolean;
}

interface ConnectedProps {
    isCanceling: boolean | undefined;
    isCanceledSuccessfully: boolean | undefined;
    failOnCanceling: boolean | undefined;
    error: ErrorType | undefined;
    Order: Orden;
}

interface MedicalServicesDispatchProps {
    CancelOrder: typeof OrderStore.actionCreators.CancelOrder;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
    isCanceling: state.orders?.candelingOrder,
    isCanceledSuccessfully: state.orders?.canceledOrderSuccessfuly,
    failOnCanceling: state.orders?.failOnCancelingOrder,
    error: state.orders?.Error,
    Order: state.ordenes?.ordenes?.find((order) => order.id === ownProps.orderId) as Orden,
});

export const mapDispatchToProps = {
    ...OrderStore.actionCreators
};

//#endregion Props