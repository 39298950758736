import { MenuItems } from './../components/Menu/MenuItems';
import { MenuItem } from './../components/Menu/MenuItem';
// import { useLocation } from 'react-router-dom';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { useHistory } from 'react-router';

interface SetLocationAction {
  type: 'SET_PAGE_ACTION';
  Location: MenuItem;
}

type KnownAction = SetLocationAction;

const GetLocation = (): string => {
  return window.location.pathname;
};

export const actionCreators = {
  SetLocationAction:
    (option: MenuItem): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: 'SET_PAGE_ACTION',
        Location: option
      });
    }
};

export interface RouteState {
  menuOption: MenuItem | undefined;
}

const unloadedState: RouteState = {
  menuOption: MenuItems.find(
    (option: MenuItem) => option.location === GetLocation()
  )
};

export const reducer: Reducer<RouteState> = (
  state: RouteState | undefined,
  incomingAction: Action
): RouteState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'SET_PAGE_ACTION':
      return {
        ...state,
        menuOption: action.Location
      };
  }

  return state;
};
